import React,{useEffect,useState} from 'react';
import { useHistory, useLocation, Link } from "react-router-dom";

//resource
import minLogo from '../../assets/log-gob-agri2.png'
import headerIcon from '../../assets/header2.png'
import { GetICon } from '../../utils/commons';
import { setUser,setSessionUser,getUser,setSessionSafe } from '../../services/storage';

const paths = {
    "/":[
        {path:"/",
        name:"Inicio",
        class:"active"
        }
    ],
    "/recovery":[
        {path:"/",
        name:"Inicio",
        class:""
        },
        {path:"/recovery",
        name:"Recuperar",
        class:"active"    
        }
    ],
    "/vedas":[
        {path:"/",
        name:"Inicio",
        class:""
        },
        {path:"/vedas",
        name:"Vedas",
        class:"active"    
        }
    ],
    "/menu":[
        {path:"/",
        name:"Inicio",
        class:""
        },
        {path:"/menu",
        name:"Opciones",
        class:"active"    
        }
    ],
    "/salvoconducto":[
        {path:"/",
        name:"Inicio",
        class:""
        },
        {path:"/menu",
        name:"Opciones",
        class:""
        },
        {path:"/salvoconducto",
        name:"Salvoconducto",
        class:"active"    
        }
    ],
    "/historial":[
        {path:"/",
        name:"Inicio",
        class:""
        },
        {path:"/menu",
        name:"Opciones",
        class:""
        },
        {path:"/historial",
        name:"Historial",
        class:"active"    
        }
    ],
    "/historial_salvo":[
        {path:"/",
        name:"Inicio",
        class:""
        },
        {path:"/menu",
        name:"Opciones",
        class:""
        },
        {path:"/historial",
        name:"Historial",
        class:""    
        },
        {path:"/historial_salvo",
        name:"Historial Salvoconducto",
        class:"active"    
        }
    ],
    "/historial_resolucion":[
        {path:"/",
        name:"Inicio",
        class:""
        },
        {path:"/menu",
        name:"Opciones",
        class:""
        },
        {path:"/historial",
        name:"Historial",
        class:""    
        },
        {path:"/historial_resolucion",
        name:"Historial Resoluciones",
        class:"active"    
        }
    ],
    "/informacion":[
        {path:"/",
        name:"Inicio",
        class:""
        },
        {path:"/menu",
        name:"Opciones",
        class:""
        },
        {path:"/informacion",
        name:"Información",
        class:"active"    
        }
    ],
    "/configuracion":[
        {path:"/",
        name:"Inicio",
        class:""
        },
        {path:"/menu",
        name:"Opciones",
        class:""
        },
        {path:"/configuracion",
        name:"Configuración",
        class:"active"    
        }
    ]
  
  };

export function HeaderComponent({isAut,setIsAuth}){

    let history = useHistory(); 
    const location = useLocation();

    const [username,getUsername] = useState("");

    const closeuser = ()=>{

        setUser(JSON.stringify([]))
        setSessionUser();
        setSessionSafe();
        setIsAuth(false);
        history.push('/') 
    }


    useEffect (()=>{
        (async ()=>{

            const user =  JSON.parse(getUser());

            if(user && user["USU_NOM"]!==undefined){
                getUsername(user["USU_NOM"])
            }else{
                getUsername("")
            }

        })();
    });

    return(
        <header  >
            <nav>
                <img className="icon-home img-min" src={minLogo} alt="aunap" />
            </nav>
            <div className="row bottom-header " >
                <div className="col-1 col-md-3" ></div>
                <div className="col-11 col-md-6" style={{textAlign:'center'}} >
                    <img className="icon-home" src={headerIcon} alt="aunap" />
                </div>
                <div className="col-12 col-md-3 col-user" >
                    <div className="content-info" >
                    {isAut === true ? 
                        <div className="btnExit" onClick={closeuser} title="Salir de la aplicación" >
                            <GetICon type={"fas"} icon={"door-open"} size={"lg"} />
                            <span>Salir</span>
                        </div>
                        :
                        <div></div>
                    }  
                    { username!=="" && isAut === true ? <span className="username" >{username}</span> : "" }
                    </div>                 
                </div>
                <div className="col-6 content-bread" >
                    { paths[location.pathname]!==undefined?
                        paths[location.pathname].map(item=>{

                            return <Link key={item.name} to={item.path} className={`link-bread ${item.class}`}  title={`ir a ${item.name}`}  >{item.name}<span>{'>'}</span></Link>

                        })
                        :
                        <div></div>
                    }
                </div>
            </div>
        </header>
    )
}