const apiKey = "wlmQ2nRV";
const baseUrl = "https://salvoconductos.aunap.gov.co/";
const baseUrlTest = "https://server.sion.hysintegrar.com/";

const urlServer = baseUrl+"AUNAP/aunap_dat_dat/v1/";
const urlServerTest = baseUrlTest+"AUNAP_5350/aunap_dat_dat/v1/";
const listPath = {"process":"_process/"  }

export{
    baseUrl,
    baseUrlTest,
    urlServer,
    urlServerTest,
    apiKey,
    listPath,
}