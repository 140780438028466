import React,{ useEffect, useState } from 'react'
import { Link, withRouter} from "react-router-dom";
import { GetICon } from '../../../utils/commons';
import {getUser,setUser, getSessionSafe,setSessionSafe } from '../../../services/storage';
import { comonHtmlForm,commonMsg,msgIcons } from '../../../utils/commons';
import { validateUserTfa } from '../../../services/auth';
import { useHistory } from "react-router-dom";

const codeField = {
    title:'Ingrese Codigo de Google Autenticator',
    list:[
        {id:"code",type:"text",placeholder:"Ingrese Codigo de Google Autenticator",required:true,errorMsg:"Necesita ingresar codigo"}
    ]
}

function MainMenuComponent()
{
    let history = useHistory()
    const [currentUser,setCurrentUser] = useState() 
    const [tfa,setTfa] = useState(1) 

    const initData = async ()=>{

        const  user =  JSON.parse(getUser());
        setCurrentUser(user);

        if(user.APL_2FA!==undefined){ 
            setTfa(Number(user.APL_2FA)); 
            if(Number(user.APL_2FA)===0){ setSessionSafe(JSON.stringify(true)); }
        }
    }  

    //#region functions
    const goToSaFe = () =>{

        let safe = getSessionSafe();

        if(safe!=="true" && tfa===1)
        { 
            comonHtmlForm(codeField,sendCode,false);
        }else{
            history.push("/salvoconducto");
        }
    }

    const sendCode = async (data)=>{

        let result = await validateUserTfa({user:currentUser.user,tfa:data.code})

        //setSessionSafe(JSON.stringify(true))
        //history.push("/salvoconducto");//*/

        if(result.ERR_COD !==undefined && result.ERR_COD !== 0 )
        {
            commonMsg("Error en el codigo",result.mng, msgIcons.error)
            
        }else if(result.data===""){
            commonMsg("Error en el codigo","Problemas en el servicio", msgIcons.error)

        }else{
            setSessionSafe(JSON.stringify(true));
            currentUser.ACC_TOK = result.ACC_TOK;

            setUser(JSON.stringify(currentUser));
            history.push("/salvoconducto");
        }//*/
    }
    //#endregion

    useEffect(()=>{

        initData();

    },[])

    return(
        <div className="parent-component" >
            <h2>Menu</h2>
            <div className="cnt-btn" >
                <div className="btn btn-round btn-high" title="Crear nuevo salvoconducto" onClick={goToSaFe} >
                    <GetICon type={"fas"} icon={"list-alt"} size={"lg"} /> &nbsp;
                    <span>Nuevo Salvoconducto</span>
                </div><br/><br/>
                <Link to="/historial" >
                    <div className="btn btn-round btn-high" title="Hitorial de salvocondutos y resoluciones" >
                        <GetICon type={"fas"} icon={"history"} size={"lg"} /> &nbsp;
                        <span>

                        Historial
                        </span>
                    </div><br/><br/>
                </Link>
                <Link to="/vedas" >
                <div className="btn btn-round btn-high" title="Consultar especies en veda" >
                    <GetICon type={"fas"} icon={"fish"} size={"lg"} /> &nbsp;
                    <span>
                    Consultar Vedas
                    </span>
                </div><br/><br/>
                </Link>
                {/*<Link to="/informacion" >
                    <div className="btn btn-round btn-high" title="Configurar opciones de usuario" >
                        <GetICon type={"fas"} icon={"info"} size={"lg"} /> &nbsp;
                        <span>Información</span>
                    </div><br/><br/>
                </Link>*/}
                <Link to="/configuracion" >
                    <div className="btn btn-round btn-high" title="Configurar opciones de usuario" >
                        <GetICon type={"fas"} icon={"cog"} size={"lg"} /> &nbsp;
                        <span>Configuración</span>
                    </div><br/><br/>
                </Link>
            </div>
        </div>
    )
} 

//export default MainMenuComponent;
export default withRouter(MainMenuComponent);