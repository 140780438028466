
const fieldLogin = {'user':{'required':true,type:'text',error:"Debe ingresar un nombre de usuario"}, 
                    'pass':{'required':true,type:'text',error:"Debe ingresar una contraseña"},        
                    'validate':{'required':true,type:'checkbox',error:"Debe acepatar las politicas de privacidad"},        
            }

export function ValidateLogin (data){

    let result = {
        continue:true,
        data:{}
    }

    for(let key in fieldLogin )
    {
        let dataField = fieldLogin[key];

        if(dataField["required"])
        {
            if(dataField["type"]==='text' && data[key] !== undefined && data[key].length > 0 )
            {
                result.data[key] = {"state":false,msg:""} ; 
            }else if(dataField["type"]==='checkbox' && data[key])
            {
                result.data[key] = {"state":false,msg:""} ; 
            }else{
                result.data[key] = {"state":true,msg:fieldLogin[key]["error"]} ;  
                result.continue = false;
            }
        }
    }

    return result;
} 

const fieldRecovery = {'password':{'required':true,type:'password',error:"Debe ingresar una contraseña valida"},        
                    'confirm':{'required':true,type:'password',error:"La confirmacion de la contraseña no coinciden"}        
            }

export function ValidateRecovery (data){

                let result = {
                    continue:true,
                    data:{}
                }

                let password = "";
                let confirm = "";

                for(let key in fieldRecovery )
                {
                    let dataField = fieldRecovery[key];
            
                    if(dataField["required"])
                    {
                        if(dataField["type"]==='password' && data[key] !== undefined && data[key].length > 0 )
                        {
                            result.data[key] = {"state":false,msg:""}; 

                            if(key==="password"){ password = data[key]; }
                            if(key==="confirm"){ confirm = data[key]; }

                        }else
                        {
                            result.data[key] = {"state":true,msg:fieldRecovery[key]["error"]} ;  
                            result.continue = false;
                        }
                    }
                }

                if(password!==confirm)
                {
                    result.data["confirm"] = {"state":true,msg:fieldRecovery["confirm"]["error"]} ;
                }
            
                return result;
            } 

const fieldSafepassange = {
    'transport':{'required':true,type:'checkbox',error:"Debe agregar un metodo de transporte"},
    'depexp':{'required':true,type:'text',error:"Debe selecionar departamento de expedición"}, 
    'depori':{'required':true,type:'text',error:"Debe selecionar departamento de origen"}, 
    'depdes':{'required':true,type:'text',error:"Debe selecionar departamento de destino"}, 
    'munexp':{'required':true,type:'text',error:"Debe selecionar municipio de expedición"}, 
    'munori':{'required':true,type:'text',error:"Debe selecionar municipio de origen"}, 
    'mundes':{'required':true,type:'text',error:"Debe selecionar municipio de destino"}, 
    'embarque':{'required':true,type:'text',error:"Debe agregar un sitio de embarque"},
    'list':{'required':true,type:'array',error:"Debe agregar al menos una especie a transportar"} 
}

export function ValidateSafepassange (data,list,noApply){

    let result = {
        continue:true,
        data:{}
    }

    for(let key in fieldSafepassange )
    {
        let dataField = fieldSafepassange[key];

        if(dataField["required"]  )
        {
            if(dataField["type"]==='checkbox' )
            {
                var transport = false;

                if(data.maritimo ){ transport = true; }
                if(data.fluvial ){ transport = true; }
                if(data.terrestre ){ transport = true; }
                if(data.aereo ){ transport = true; }

                if(transport || noApply)
                {
                    result.data[key] = {"state":false,msg:""}; 
                }else{
                    result.data[key] = {"state":true,msg:fieldSafepassange[key]["error"]} ;  
                    result.continue = false;
                }

            }else if(dataField["type"]==='text' && data[key] !== undefined && data[key]!=="" )
            {
                result.data[key] = {"state":false,msg:""} ; 
            }else if(dataField["type"]==='array' && list.length > 0  )
            {
                result.data[key] = {"state":false,msg:""} ; 
            }else
            {
                result.data[key] = {"state":true,msg:fieldSafepassange[key]["error"]} ;  
                result.continue = false;
            }
        }
    }

    return result;
}