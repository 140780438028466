import axios from "axios"
import { urlServer,apiKey,listPath,urlServerTest } from '../constans/global'
import { setDeptos, setMunicipality,setVedas,setLimitOrnamentales,setFormatPresentation,setValidDays,setInfo,setPermissions,setRemissions } from './storage'
import {getUserType}  from '../services/storage';

export function getUpdates(date){

    var path = urlServer;
    const host = window.location.host;

    if(getUserType()==="test"){  path = urlServerTest; }
    if(host.search("localhost")>-1 || host.search("hysintegrar")>-1 ){ path = urlServerTest; }

    let dateSend= "";

    if(date!==""){
        //dateSend= "&param['FCH_VER']="+date;
    }

    axios.get(path+listPath["process"]+'API_ACT?api_key='+apiKey+dateSend )
    .then(response=>{
        setDeptos(JSON.stringify(response.data["departamentos"]));
        setMunicipality(JSON.stringify(response.data["municipios"]));
        setFormatPresentation(JSON.stringify(response.data["formapresentacion"]));
        setVedas(JSON.stringify(response.data["vedas"]));
        setLimitOrnamentales(response.data["sin_tope_ornamental"])
        setPermissions(JSON.stringify(response.data["tipopermiso"]))
        setRemissions(JSON.stringify(response.data["tiporemision"]));
        setValidDays(response.data["dss_vig_tra"]+"");
        setInfo(JSON.stringify(response.data["informacion"]));
        //lista de vedas / formas de presentacion / fecha de actualizacion 

    })
    .catch(error=>{
        console.log("error")
        console.log(error)
    });
}

export function getUpdateDepto()
{
    var path = urlServer;
    if(getUserType()==="test"){  path = urlServerTest; }

    axios.get(path+listPath["process"]+'API_DEP_MUN?api_key='+apiKey)
    .then(response=>{
        setDeptos(JSON.stringify(response.data["departamentos"]));
        setMunicipality(JSON.stringify(response.data["municipios"]));
        
    })
    .catch(error=>{
        console.log("error")
        console.log(error)
    });
}






