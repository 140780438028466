import React,{ useEffect,useState } from 'react'
import { BrowserRouter as Router,Switch,Route } from 'react-router-dom';

/** Componets loaded */
import { LoginComponent } from './components/screens/auth/LoginComponent'
import { InfoComponent } from './components/screens/auth/InfoComponent'
import { ProfileComponent  } from './components/screens/auth/ProfileComponent'
import { VedasComponents } from './components/screens/data/VedasComponents'
import  MainMenuComponent from './components/screens/menus/MainMenuComponent'
import { SafePassageComponent } from './components/screens/data/SafePassageComponent'
import HistoryMenuComponent from './components/screens/menus/HistoryMenuComponent';
import IdleTimerComponent from './components/elements/IdleTimerComponent';
import { HeaderComponent } from './components/elements/HeaderComponent';
import { RecoveryComponent } from './components/screens/auth/RecoveryComponent';

/** style */
import "./style/main.css"
/** Services and utils */
import { getDateUpdate } from './services/storage'
import { getUpdates } from './services/update';
import { getSessionUser} from './services/storage'
import  ProtecteRouteComponent  from './components/elements/ProtectedRouteComponent';
import ProtectedRecoveryComponent from './components/elements/ProtectedRecoveryComponent'
import HistorySafePassageComponent from './components/screens/data/HistorySafePassageComponent';
import HistoryResolutionComponent from './components/screens/data/HistoryResolutionComponent';

function App() 
{
  const [isAuth,setIsAuth] = useState(false);
  const [isRecovery,setIsRecovery] = useState(false);
  const [dataUser,setDataUser] = useState({});
  
  useEffect(()=>{
    
    const updateDate = getDateUpdate()
    
    let currentDate = new Date();
    let lastDate = currentDate.getFullYear()+"-"+(currentDate.getMonth()+1)+"-"+currentDate.getDate();

    if(updateDate==null){ lastDate="";    }

      getUpdates(lastDate);

      const session = getSessionUser();

    if(session && session==="true" )
    {
      setIsAuth(true);
    }

  },[])

  return(
    
    <Router>
    {/*<Router basename={'/app'} >*/}
      <div className="container-fluid" >
        <IdleTimerComponent isAut={isAuth} setAuth={setIsAuth} isRecovery={isRecovery} setIsRecove ry={setIsRecovery} />
        <HeaderComponent isAut={isAuth} setIsAuth={setIsAuth} />
        <Switch>
            <ProtectedRecoveryComponent path="/recovery" component={RecoveryComponent} isRecovery={isRecovery} userData={dataUser}   />
            <ProtecteRouteComponent path="/menu" component={MainMenuComponent } isAuth={isAuth}  setAuth={setIsAuth} />
            <ProtecteRouteComponent path="/salvoconducto" component={SafePassageComponent } isAuth={isAuth}  setAuth={setIsAuth} />
            {/*<ProtecteRouteComponent path="/informacion" component={InfoComponent} isAuth={isAuth}  setAuth={setIsAuth} />*/}
            <ProtecteRouteComponent path="/configuracion" component={ProfileComponent} isAuth={isAuth}  setAuth={setIsAuth} />
            <ProtecteRouteComponent path="/historial" component={HistoryMenuComponent} isAuth={isAuth}  setAuth={setIsAuth} />
            <ProtecteRouteComponent path="/historial_salvo" component={HistorySafePassageComponent} isAuth={isAuth}  setAuth={setIsAuth} />
            <ProtecteRouteComponent path="/historial_resolucion" component={HistoryResolutionComponent} isAuth={isAuth}  setAuth={setIsAuth} />
            <Route path="/informacion" >
                <InfoComponent />
            </Route>
            <Route path="/vedas" >
                <VedasComponents />
            </Route>
            <Route path="/" >
                <LoginComponent  setAuth={setIsAuth} setIsRecovery={setIsRecovery} setDataUser={setDataUser}  />
            </Route>
      </Switch>
      </div>  
    </Router>
  );

}

export default App;
