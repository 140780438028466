import React, { useEffect, useReducer, useState } from "react";

import { commonsTitle } from '../../../utils/commons'
import { useHistory } from "react-router-dom";
import { GetICon } from '../../../utils/commons'
import { ValidateRecovery } from "../../elements/ValidateFormComponent"
import { getValidCode,changePasswordRecovery } from "../../../services/auth";
import {commonMsg,msgIcons,commonToast } from "../../../utils/commons"

const formReducer = (state,event)=>{

    return{
        ...state,
        [event.name]:event.value
    }
}

const dataForm = {
    user:"",
    password:"",
    confirm:"",
    last:"",
    ACC_TOK:""
}


const errorModel = {
    user:{state:false,msg:""},
    password:{state:false,msg:""},
    confirm:{state:false,msg:""},
    last:{state:false,msg:""}
    }

export function RecoveryComponent(props){

    //title
    commonsTitle("Recuperar contraseña");

    //
    let history = useHistory()

    const [formData,setFormData] = useReducer(formReducer,dataForm);
    const [validateError,setValidateError] = useState(errorModel);

    const changeInput = event =>{

        setFormData({name:event.target.name,value:event.target.value,input:event.target})
    }

    const sendForm = (event) =>
    {
        event.preventDefault();

        const resultValidate  = ValidateRecovery(formData)

        setValidateError(resultValidate.data);
        
        if(!resultValidate.continue)
        {
            return;
        }

        changePasswordRecovery(formData).then(data=>{

            if(data.state){
                
                commonToast("Contraseña cambiada con exito",msgIcons.success);
                history.push('/') 
            }else{
                commonMsg("Error al intentar ingresar",data.mng, msgIcons.error)
            }//*/
        }).catch(error=>{

        });//*/

        event.preventDefault()
        return false;//*/
    }

    const callGoBack =  ()=>{ history.goBack(); }

    useEffect (()=>{

        setFormData({name:"user",value:props.userData.user,input:null});
        setFormData({name:"ACC_TOK",value:props.userData.token,input:null});

        getValidCode({user:props.userData.user,token:props.userData.token})
        .then(data=>{

                if(data.state)
                {
                    setFormData({name:"last",value:data.code,input:null});
                }else{
                    history.goBack();
                }
            //
        }).catch(error=>{

        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <div className="parent-component" >
            <button className="btn btn-primary return" onClick={callGoBack} title="Regresar a la vista anterior"  > 
                <GetICon type={"fas"} icon={"arrow-alt-circle-left"} size={"lg"} /> &nbsp;
                Regresar
            </button><br/><br/>
            <div className="parent-form" >
                <h3>Recuperar contraseña</h3><br/><br/>
                <form onSubmit={sendForm}   >
                    <label>
                        <span>Usuario </span><br/>
                        <input type="text" name="user" placeholder="Usuario" className="form-control" value={formData.user} readOnly />
                    </label>
                    <label>
                        <span>Nueva Contraseña </span><br/>
                        <input type="password" name="password" placeholder="Contraseña" className="form-control" value={formData.password} onChange={changeInput} />
                        <div className="error-form" >{ validateError.password.state ? validateError.password.msg : "" } </div>
                    </label>
                    <label>
                        <span>Confrimar Contraseña</span><br/>
                        <input type="password" name="confirm" placeholder="Confirmación" className="form-control" value={formData.confirm} onChange={changeInput} />
                        <div className="error-form" >{ validateError.confirm.state ? validateError.confirm.msg : "" } </div>
                    </label>
                    <br/>
                    <input type="submit" value="Enviar" className="btn btn-success" title="Enviar formulario" />
                </form>
            </div>
        </div>

    )


}