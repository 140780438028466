import React from "react";
import { Route,Redirect } from "react-router-dom"; 
import { getSessionUser} from '../../services/storage';
import { setUser,setSessionUser,setSessionSafe } from '../../services/storage';

function ProtecteRouteComponent({isAuth:IsAuth, setAuth:SetIsAuth ,component:Component,...rest}){

    const session = getSessionUser();

    return <Route {...rest} render={(props)=>{

        if(IsAuth || session==="true"){
            return <Component setIsAuth= {SetIsAuth} />
        } else {

            setUser(JSON.stringify([]))
            setSessionUser();
            setSessionSafe();

            return <Redirect to={{patname:"/",state:{from:props.location}}}  />
        }

    }} />;
}

export default ProtecteRouteComponent;