import { useEffect,useState,useRef } from 'react';
import { GetICon} from '../../utils/commons';

export const DropDowncomponent = (props)=>{

    const [show,setShow] = useState(false);
    const [listenig,setListenig] = useState(false);
    const dropRef = useRef(null);

    const focus = ()=>{    

        setShow(true);
        props.focus();
    } 

    const selected = (value)=>{

        props.select(value)
        setShow(false);
    }

    useEffect(()=>{

        if(show){

            if(!listenig){

                setListenig(true)

                const parent = document.getElementsByClassName("container-fluid");

                parent[0].addEventListener('click',(evt)=>{

                    const current = dropRef.current;
                    const node = evt.target
                    if(current.contains(node)) return

                    setShow(false)
                },true)//*/
            }
        }
    },[show])

    return (
        <div className='parent-drop'  ref={dropRef} onFocus={focus} >
            <input type="text" name="munDes" className="form-control" value={props.selected} 
                onChange={e=>props.change(e.target.value)} autoComplete="off" />
            <div className="error-form" >{ props.state && props.msg } </div>
            { show &&
                <div className="view-search"   >
                { props.list.map(item=>{

                    let text = "";

                    for(let inFor in props.format){
                        const index = props.format[inFor]
                        text+= item[index];
                    }

                    return(
                        <>
                            {props.type==="species" ?

                                <div key={props.basekey+""+item[props.valueKey]} className="row item-search" onClick={()=>selected(item)} >
                                    <div className={item.disabled? "col-1 disabled" :'col-1 enabled'} >
                                        <GetICon type={"fas"} icon={"fish"} size={"lg"} color="#000000" />
                                    </div>
                                    <div className='col-11'>
                                        <span>Codigo:</span> {item.ESP_COD_INT}
                                    </div>
                                    <div className='col-12' ><span>Nombre Cientifico:</span>{item.ESP_NOM_CIE}</div>
                                    <div className='col-12' ><span>Nombre Comun:</span>{item.ESP_NOM_JSO}</div>
                                    <div className='col-6' ><span>Ambito:</span>{item.AMB_NOM}</div>
                                    {item.type!=="" &&
                                        <div className='col-6' ><span>Tipo:</span>Por Especie</div>
                                    }
                                    {item.DEP_NOM!=="" &&
                                        <div className='col-6' ><span>Departamento:</span>{item.DEP_NOM}</div>
                                    }
                                    {item.MUN_NOM!=="" &&
                                        <div className='col-6' ><span>Municipio:</span>{item.MUN_NOM}</div>
                                    }
                                    {item.CUE_NOM!=="" &&
                                        <div className='col-6' ><span>Cuenca:</span>{item.CUE_NOM}</div>
                                    }
                                    <div className='col-6' ><span>Desde:</span>{item.FCH_DES}</div>
                                    <div className='col-6' ><span>Hasta:</span>{item.FCH_HAS}</div>
                                </div>
                                :
                                <div key={props.basekey+""+item[props.valueKey]}  className="item-search" onClick={()=>selected(item)}  >{text}</div>
                            }
                        </>
                        )
                    })
                }
                </div> 
            }
        </div>
    )
}