import React from "react";
import { Route,Redirect } from "react-router-dom"; 
import { getSessionUser} from '../../services/storage'

function ProtecteRouteComponent({isRecovery, setIsisRecovery,component:Component,userData:UserData ,...rest}){

    const session = getSessionUser();

    return <Route {...rest} render={(props)=>{

        if(isRecovery || session==="true"){
            return <Component userData={UserData} />
        } else {

            return <Redirect to={{patname:"/",state:{from:props.location}}}  />
        }

    }} />;
}

export default ProtecteRouteComponent;