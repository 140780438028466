//import modules
import  axios from 'axios'
import { urlServer,urlServerTest,listPath,apiKey } from '../constans/global';
//import services
import { setUser,setSessionUser,setUserType,getUserType } from '../services/storage';



export const accessUser  =  async (data) =>
{
    var path = urlServer;
    var typeUser = "web";

    if(data.user.search("PHYS")>-1){ 
        path = urlServerTest; 
        typeUser = "test";
    }

    const user = await axios.get(path+listPath["process"]+'API_ACC_USU?api_key='+apiKey+'&param[USU_ID]='+data["user"]+'&param[USU_PASS]='+data["pass"])

    let userData = user.data;

    if(userData.ERR_COD!==undefined && userData.ERR_COD !== 0 ){
        return  {state:false,mng:userData.ERR_DES};
    }else if(userData.data===""){
        return  {state:false,mng:"Problemas en el servicio"};
    }else{

        userData["user"]=data["user"];

        setUser(JSON.stringify(userData))
        setUserType(typeUser);
        setSessionUser(JSON.stringify(true));
        return  {state:true}
    }

}

export const validateUserChangePassword = async (data)=>{
    
    var path = urlServer;
    if(getUserType()==="test"){  path = urlServerTest; }
    

    const resultUpdate = await axios.get(path+listPath["process"]+'API_ACC_2FA?api_key='+apiKey+'&param[USU_ID]='+data["user"]+'&param[USU_PASS]='+data["tfa"]);

    const userData = resultUpdate.data;

    if(userData.ERR_COD !==undefined && userData.ERR_COD !== 0 )
    {
        return  {state:false,mng:userData.ERR_DES};
    }else if(userData.data===""){
        return  {state:false,mng:"Problemas en el servicio"};
    }else{
        return userData;
    }
}

export const getValidCode = async (data) =>{

    var path = urlServer;
    if(getUserType()==="test"){  path = urlServerTest; }

    const resultCode = await axios.post(path+listPath["process"]+'API_OLV_PSW?api_key='+apiKey,{USU_ID:data["user"],"ACC_TOK":data["token"]});
    const userData = resultCode.data;
    
    if(userData.ERR_COD<1){
        return {state:false,mng:userData.ERR_DES};
    }else{
        return {state:true,code:userData.ERR_DES};
    }
}

export const validateUserTfa = async (data)=>{

    var path = urlServer;
    if(getUserType()==="test"){  path = urlServerTest; }
    
    const resultUpdate = await axios.get(path+listPath["process"]+'API_ACC_2FA?api_key='+apiKey+'&param[USU_ID]='+data["user"]+'&param[USU_PASS]='+data["tfa"]);
    const userData = resultUpdate.data;

    return userData;
}

export const changePassword = async (data) =>{

    var path = urlServer;
    if(getUserType()==="test"){  path = urlServerTest; }

    var send ={
        USU_ID:data["email"],
        USU_PASS1:data["current"],
        USU_PASS2:data["new"],
        ACC_TOK:data["token"],
    }//*/

    const resultUpdate = await axios.post(path+listPath["process"]+'API_CAM_PWD?api_key='+apiKey,send);
    const dataResult = resultUpdate.data; 

    if(dataResult.ERR_COD!==undefined && dataResult.ERR_COD !== 1 ){
        return  {state:false,mng:dataResult.ERR_DES};
    }else if(dataResult.data==="" ){
        return  {state:false,mng:"Problemas en el servicio"};
    }else{
        return  {state:true}
    }//*/
}

export const changePasswordRecovery = async (data) =>{

    var path = urlServer;
    if(getUserType()==="test"){  path = urlServerTest; }

    var send ={
        USU_ID:data["user"],
        USU_PASS1:data["last"],
        USU_PASS2:data["password"],
        ACC_TOK:data["ACC_TOK"],
    }

    const resultUpdate = await axios.post(path+listPath["process"]+'API_CAM_PWD?api_key='+apiKey,send);
    const dataResult = resultUpdate.data; 

    if(dataResult.ERR_COD!==undefined && dataResult.ERR_COD !== 1 ){
        return  {state:false,mng:dataResult.ERR_DES};
    }else if(dataResult.data==="" ){
        return  {state:false,mng:"Problemas en el servicio"};
    }else{
        return  {state:true}
    }
}