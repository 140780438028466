import React,{  useState,useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { GetICon } from '../../../utils/commons'
import { getInfo } from '../../../services/storage'

//load utils
import {commonsTitle} from "../../../utils/commons"

export function InfoComponent(){

     //title
     commonsTitle("Información")

     //form
    const[info,setInfo]= useState([]);

    let history = useHistory()
 
    const callGoBack =  ()=>{
        history.goBack();
    }

    const getInfoData = async ()=>{
        
        const list = getInfo();
        setInfo(JSON.parse( list));
    }

    useEffect(  ()=>{

        getInfoData()

    },[])

    return(
        <div class="parent-component"  >
            <button className="btn btn-primary return return-margin" onClick={callGoBack} title="Regresar a la vista anterior" > 
                <GetICon type={"fas"} icon={"arrow-alt-circle-left"} size={"lg"} /> &nbsp;
                Regresar
            </button><br/><br/>
            <h3>Información</h3><br/><br/>
                <div class="cnt-config" >
                    {info.length>0 &&
                        info.map(item=>{

                            return(
                                <div key={"info_"+item.ID}  className='item-link' >
                                    <a href={item.NAME} target='_blank' >
                                        <div className='icon' >
                                            <GetICon type={"fas"} icon={"info"} size={"1x"} /> &nbsp;
                                        </div>
                                        {item.DES}
                                    </a>
                                </div>
                            )

                        })
                    }
                </div>
       
        </div>
    )

}