import React,{ useReducer, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { GetICon } from '../../../utils/commons'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { changePassword } from '../../../services/auth'
import { getUser } from '../../../services/storage'

//load utils
import {commonsTitle,commonMsg,msgIcons, commonToast } from "../../../utils/commons"

const formChange = {
    email:"",
    current:"",
    new:"",
    confirm:"",
    token:""
}

const formReducer = (state,event)=>{

    return{
        ...state,
        [event.name]:event.value
    }
}

export function ProfileComponent(){

     //title
     commonsTitle("Perfil Usuario")

     //form
    const [formData,setFormData] = useReducer(formReducer,formChange);

    const changeInput = event =>{
        setFormData({name:event.target.name,value:event.target.value})
    }

    let history = useHistory()

    const sendForm = (event) =>
    {
        changePassword(formData).then(data=>{

            if(data.state){

                commonToast("Actualizacion exitosa",msgIcons.success);
                // history.push('/menu') 

            }else{
                commonMsg("Error al intentar actualizar",data.mng, msgIcons.error)
            }
        }).catch(error=>{

        });

        event.preventDefault()
        return false;
    }

    const callGoBack =  ()=>{
        history.goBack();
    }

    useEffect(()=>{

        const user = JSON.parse(getUser());

        if(user["USU_NOM"]!==undefined){
            //setUser(user["USU_NOM"])
            setFormData({name:"email",value:user.user});
            setFormData({name:"token",value:user["ACC_TOK"]});
        }
    },[])

    return(
        <div class="parent-component"  >
            <button className="btn btn-primary return return-margin" onClick={callGoBack} title="Regresar a la vista anterior" > 
                <GetICon type={"fas"} icon={"arrow-alt-circle-left"} size={"lg"} /> &nbsp;
                Regresar
            </button><br/><br/>
            <h3>Configuración</h3><br/><br/>
                <div class="cnt-config" >
                    <Accordion>
                        <AccordionSummary expandIcon={<GetICon type={"fas"} icon={"chevron-down"} size={"sm"} />}  >
                            <Typography>Cambio de contraseña</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div class="parent-form" >
                                <form onSubmit={sendForm}   >
                                    <label>
                                        <span>Usuario</span><br/>
                                        <input type="text" name="email" class="form-control" defaultValue={formData.email}  onChange={changeInput} disabled="disabled"  />
                                    </label>
                                    <label>
                                        <span>Contraseña Actual </span><br/>
                                        <input type="password" name="current" placeholder="Contraseña Actual" class="form-control" onChange={changeInput} />
                                    </label>
                                    <label>
                                        <span>Nueva Contraseña </span><br/>
                                        <input type="password" name="new" placeholder="Contraseña" class="form-control" onChange={changeInput} />
                                    </label>
                                    <br/>
                                    <label>
                                        <span>Confirmar Contraseña </span><br/>
                                        <input type="password" name="confirm" placeholder="Confirmar Contraseña" class="form-control" onChange={changeInput} />
                                    </label>
                                    <br/>
                                    <input type="submit" value="Enviar" class="btn btn-success" />
                                </form>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
       
        </div>
    )

}