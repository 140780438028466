import { useEffect, useState } from 'react';
import { GetICon} from '../../utils/commons';

export const DirectionsModal = (props)=>{

    const [list,setList] = useState([]);
    const [listRemissions,setListRemissions] = useState([]);
    const [selecteRemission,setSelecteRemission] = useState(0);

    const [step,setStep] = useState(0);

    const selectedItem = (id)=>{

        const proList = [...[],...list];
        const index = proList.findIndex((item)=>item.ID===id);

        if(proList[index].cheked!== undefined && proList[index].cheked){ proList[index].cheked = false
        }else{ proList[index].cheked = true }

        setList([...proList])
    }

    const nextStep = ()=>setStep(1)
    const previewStep = () =>setStep(0)
    
    const selectedRemission = (id)=>{

        setSelecteRemission(id)
    }

    const aceptModal = ()=>{
        props.save({list,selecteRemission})
        setList([])
    }

    const closedModal = ()=>{

        props.cancel();
    }

    useEffect(()=>{

        console.log("props:::: ",{props})

        if(props.list.length>0){ 
            
            let list = []

            props.list.map(item=>{ list.push({ID:item.ID,DES:item.DES,cheked:false}) })
            
            setList(list) 
        }

        setStep(0);
        setSelecteRemission(0);
    },[])

    return(
        <div className={props.view ? "modal modal-view":"modal" } >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {step===0 ? "Direcciones Disponibles" : "Remiciones dipónibles"}
                        </h5>
                    </div>
                    <div className="modal-body">
                        <div className="row content-module-list" >
                            {step===0 ? 

                                list.map((item)=>{

                                    let classItem = "item"    

                                    if(item.cheked){ classItem = "item-selected" }
                                        
                                    return(
                                            <div  key={"directions_"+item.ID} className={classItem} onClick={()=>{selectedItem(item.ID)}} >
                                                <div className="text" >{item.DES}</div>
                                                <div className="mark"  >
                                                    <GetICon type={"fas"} icon={"check"} size={"xs"} />
                                                </div>
                                            </div>
                                        )
                                    })
                                
                                :
                                props.listRemissions.map((item)=>{
                                    let classItem = "item"    
                                    
                                    if(Number(selecteRemission)===Number(item.ID)){ classItem = "item-selected" }
                                    
                                    return(

                                        <div  key={"remissions_"+item.ID} className={classItem} onClick={()=>{selectedRemission(item.ID)}} >
                                            <div className="text" >{item.NAME}</div>
                                                <div className="mark"  >
                                                    <GetICon type={"fas"} icon={"check"} size={"xs"} />
                                                </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="modal-footer" >
                        <div className="row" >
                            {step===0 ? 
                                <>
                                    <div className="col-12 col-md-3 btn-bottom" >
                                        <button type="button" className="btn btn-primary" onClick={nextStep} >Siguiente</button>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="col-12 col-md-3 btn-bottom" >
                                        <button type="button" className="btn btn-primary" onClick={previewStep} >Atras</button>
                                    </div>
                                    <div className="col-12 col-md-3 btn-bottom" >
                                        <button type="button" className="btn btn-primary" onClick={aceptModal} >Aceptar</button>
                                    </div>
                                </>
                            }
                            <div className="col-12 col-md-3 btn-bottom" >
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={closedModal} >Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )

}