import React,{} from 'react';
import { Link, useHistory/*, withRouter*/ } from "react-router-dom";
import { GetICon } from '../../../utils/commons';

function HistoryMenuComponent(){

    let history = useHistory()

    const callGoBack =  ()=>{
        history.goBack();
    }


    return(
        <div className="parent-component" >
            <button className="btn btn-primary return return-margin" onClick={callGoBack} title="Regresar a la vista anterior" > 
                <GetICon type={"fas"} icon={"arrow-alt-circle-left"} size={"lg"} /> &nbsp;
                Regresar
            </button><br/><br/>
            <h2>Historial</h2>
            <div className="cnt-btn" >
                <Link to="/historial_salvo" >
                    <div className="btn btn-primary" title="Consultar Salvoconductos" >
                        <GetICon type={"fas"} icon={"clipboard-list"} size={"lg"} /> &nbsp;
                        <span>Salvoconductos</span>
                    </div><br/><br/>
                </Link>
                <Link to="/historial_resolucion" >
                    <div className="btn btn-primary" title="Consultar Resolciones"  >
                        <GetICon type={"fas"} icon={"file-alt"} size={"lg"} /> &nbsp;
                        <span>Resolución</span>
                    </div><br/><br/>
                </Link>
                
            </div>
        </div>
    )

}

export default HistoryMenuComponent;
//export default withRouter(MainMenuComponent);