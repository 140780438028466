//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faListAlt, faHistory,faCog,faClipboardList, faFileAlt,faArrowAltCircleLeft,faDoorOpen,faFish,faPlusCircle,faChevronDown,faSave,faTimesCircle,faTruck,
        faQuestionCircle,faDownload,faWindowClose,faCheck,faInfo,faCompass
    } from '@fortawesome/free-solid-svg-icons';

library.add({
    faListAlt,
    faHistory,
    faCog,
    faClipboardList,
    faFileAlt,
    faArrowAltCircleLeft,
    faDoorOpen,
    faFish,
    faPlusCircle,
    faChevronDown,
    faSave,
    faTimesCircle,
    faTruck,
    faQuestionCircle,
    faDownload,
    faWindowClose,
    faCheck,
    faInfo,
    faCompass
})