import axios from "axios"
import { baseUrl,baseUrlTest,urlServer,apiKey,listPath,urlServerTest } from '../constans/global';
import {getUserType}  from '../services/storage';




export async function getAllVedas(){

    var path = urlServer;
    if(getUserType()==="test"){  path = urlServerTest; }
    
    const listVedas = await axios.get(path+listPath["process"]+'API_TOD_VDA?api_key='+apiKey)
    
    return listVedas.data;
}

export async function getAllVedasMuni(id){

    var path = urlServer;
    if(getUserType()==="test"){  path = urlServerTest; }

    const listVedas = await axios.get(path+listPath["process"]+'api_vda?api_key='+apiKey+'&param[mun_id]='+id)

    return listVedas.data;
}

export async function getAllSafePassage(id=""){

    var path = urlServer;
    if(getUserType()==="test"){  path = urlServerTest; }

    const listSafeAll = await axios.get(path+listPath["process"]+'API_SLV_HIS?api_key='+apiKey+"&param[ENT_SLV]="+id);

    return listSafeAll.data;
}

export async function removeSafePassage(data)
{
    var path = urlServer;
    if(getUserType()==="test"){  path = urlServerTest; }

    const result = await axios.post(path+listPath["process"]+'API_SLV_ANU?api_key='+apiKey,data);
    const dataResult = result.data; 

    if(dataResult.ERR_COD !== undefined && dataResult.ERR_COD < 1 ){
        return  {state:false,mng:dataResult.ERR_DES};
    }else if(result.data===""){
        return  {state:false,mng:"Problemas en el servicio"};
    }else{
        return  {state:true,id:dataResult.ID_SLV}
    }
}

export async function getUserResolutions(id=""){

    var path = urlServer;
    if(getUserType()==="test"){  path = urlServerTest; }

    const resultResolutions = await axios.get(path+listPath["process"]+'API_RSO?api_key='+apiKey+"&param[ENT_RSO]="+id);
    const dataResult = resultResolutions.data; 

    return dataResult;
}

export async function sendSafepassange(data)
{
    var path = urlServer;
    if(getUserType()==="test"){  path = urlServerTest; }

    const result = await axios.post(path+listPath["process"]+'API_SLV_AUT?api_key='+apiKey ,data);
    const dataResult = result.data; 

    if(dataResult.ERR_COD !== undefined && dataResult.ERR_COD < 1 ){
        return  {state:false,mng:dataResult.ERR_DES};
    }else if(result.data===""){
        return  {state:false,mng:"Problemas en el servicio"};
    }else{
        return  {state:true,id:dataResult.ID_SLV}
    }

}

export async function getDocument(data){

    var path = baseUrl;
    if(getUserType()==="test"){  path = baseUrlTest; }

    const result = await axios.get(path+'/AUNAP/SLV_GEN_PDF_B64.pro?ID_SLV='+data)
    const dataResult = result.data; 

    if(dataResult.ERR_COD !== undefined && dataResult.ERR_COD !== 1 ){
        return  {state:false,mng:dataResult.ERR_DES};
    }else if(result.data===""){
        return  {state:false,mng:"Problemas en el servicio"};
    }else if(dataResult.ERR_COD === 1){
        return  {state:true,pdf:dataResult.PDF}
    }

}

export async function getAllResolutions(id=""){

    var path = urlServer;
    if(getUserType()==="test"){  path = urlServerTest; }

    let addId = "";

    if(id!==""){
        addId = "/"+id
    }

    const listSafeAll = await axios.get(path+'/anp_rso'+addId+'?api_key='+apiKey);

    return listSafeAll.data;
}


export async function getSpecieByWord(word="")
{
    var path = urlServer;
    if(getUserType()==="test"){  path = urlServerTest; }

    const listSafeAll = await axios.get(path+'/anp_esp?api_key='+apiKey+"&filter[parts]="+word);
 
    return listSafeAll.data;
} 