//load modules 
import React, { useReducer, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { accessUser,validateUserChangePassword } from '../../../services/auth'
import { ValidateLogin, } from '../../elements/ValidateFormComponent'



//load utils
import {commonsTitle,commonMsg,msgIcons, commonToast,comonHtmlForm,GetICon } from "../../../utils/commons"

//resource
const formLogin =
{
    user:"",
    pass:"",
    validate:false
}

const errorModel = {
    user:{state:false,msg:""},
    pass:{state:false,msg:""},
    validate:{state:false,msg:""}
    }

const recoveryField = {title:"Recuperar Contraseña",
                        list:[{id:"user",type:"text",placeholder:"Ingrese Usuario",required:true,errorMsg:"Nesecita ingresar un usuario"}
                            ,{id:"tfa",type:"text",placeholder:"Ingrese Codigo validacion",required:true,errorMsg:"Nesecita el codigo de validación"}
                            ]
                    }

const formReducer = (state,event)=>{

    return{
        ...state,
        [event.name]:event.value
    }
}

export function LoginComponent(props){

    //title
    commonsTitle("Iniciar Usuario");

    //form
    //const isAuth = useSelector(state=>state.userLog);
    const [formData,setFormData] = useReducer(formReducer,formLogin);
    const [validateError,setValidateError] = useState(errorModel);
    let history = useHistory();    

    const changeInput = event =>{

        let value = event.target.value;

        if(event.target.type==="checkbox"){ value = event.target.checked; }

        setFormData({name:event.target.name,value:value,input:event.target})
    }


    const sendForm = (event) =>
    {
        event.preventDefault();

        const resultValidate  = ValidateLogin(formData)
        setValidateError(resultValidate.data);
        
        if(!resultValidate.continue){ return; }

        accessUser(formData).then(data=>{

            if(data.state){
                
                props.setAuth(true);
                commonToast("Inicio con exito",msgIcons.success);
                history.push('/menu') 
            }else{
                commonMsg("Error al intentar ingresar",data.mng, msgIcons.error)
            }
        }).catch(error=>{

        });//*/

        event.preventDefault()
        return false;//*/
    }

    const sendPassRecovery = async (data) =>{

        let result = await validateUserChangePassword(data)

        if(result.state!==false)
        {
            props.setIsRecovery(true);
            props.setDataUser({id:result.USU_ID,user:data.user,token:result.ACC_TOK});
            history.push('/recovery') 
        }else{
            commonMsg("Error al intentar recuperar",result.mng, msgIcons.error)
        }//*/
    }

    return(
        <div className="parent-component" >
            <div className="parent-form" >
                <h3>Gestor de salvoconductos</h3><br/><br/>
                <form onSubmit={sendForm}   >
                    <h3>Ingresar</h3><br/>
                    <label>
                        <span>Usuario </span><br/>
                        <input type="text" name="user" placeholder="Usuario" className="form-control" onChange={changeInput} />
                        <div className="error-form" >{ validateError.user.state ? validateError.user.msg : "" } </div>
                    </label>
                    <br/>
                    <label>
                        <span>Contraseña </span><br/>
                        <input type="password" name="pass" placeholder="Contraseña" className="form-control" onChange={changeInput} />
                        <div className="error-form" >{ validateError.pass.state ? validateError.pass.msg : "" } </div>
                    </label>
                    <br/>
                    <label>
                        <input type="checkbox" name="validate" className="" onChange={changeInput} />
                        <div className='cnt-privacy' > Esta de acuerdo con  &nbsp;
                            <a href={'https://www.aunap.gov.co/politicas/'} target={'_blank'} title="Ir a las politicas de privacidad" rel="noreferrer" > 
                            nuestras Politicas de privacidad.
                            </a> 
                        </div>
                        <div className="error-form" >{ validateError.validate.state ? validateError.validate.msg : "" } </div>
                    </label>
                    <input type="submit" value="Ingresar" className="btn btn-success" title="Enviar datos" />
                    <div>
                        <div className="rec-input"  onClick={()=>{comonHtmlForm(recoveryField,sendPassRecovery)}} title="Recuperar contraseña"  >
                            Recuperar contraseña 
                        </div>
                    </div>  
                </form>
                <div className="cnt-btn" >
                    <Link to="vedas" >
                        <button className="btn btn-vedas" title="Ver lista de Vedas" >Consultar Vedas</button>
                    </Link>
                </div>
                <div className="cnt-btn info"  >
                    <Link to="/informacion" >
                        <button className="btn btn-info" title="Ver lista de Vedas" >
                            Información &nbsp;
                            <GetICon type={"fas"} icon={"question-circle"} size={"lg"} color="#000000" />
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );

}