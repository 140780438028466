import React, {useRef} from "react";
import IdleTimer from "react-idle-timer"; 
import { useLocation,useHistory } from 'react-router-dom'
import {setSessionUser,setSessionSafe } from '../../services/storage'

function IdleTimerComponent({isAut,setAuth,isRecovery,setIsRecovery}){

    const idleTimerRef = useRef(null);
    const location = useLocation();
    let history = useHistory();    

    const onIdle = () =>{

        if(isAut && location.pathname!=="/vedas"  ){
            setAuth(false);
            setSessionUser();
            setSessionSafe();
            history.push('/') 
        }

        if(isRecovery && location.pathname==="/recovery")
        {
            setIsRecovery(false);
            history.push('/');
        }
    }

    return (<div>
        <IdleTimer ref={idleTimerRef} timeout={1800*1000} onIdle={onIdle} ></IdleTimer>
    </div>);
}

export default IdleTimerComponent;