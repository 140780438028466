import React,{ useEffect, useState, useReducer } from 'react';
import { useHistory } from "react-router-dom";
import { commonToast, GetICon,msgIcons,commonMsg,commonConfirm  } from '../../../utils/commons';
import { GenerateDocument } from '../../../utils/pdf';
import { getUserResolutions, sendSafepassange,getDocument } from '../../../services/queries';
import { getUser,setSessionSafe,getSessionSafe,getDeptos, getMunicipality,getFormatPresentation,getValidDays, getVedas,getPermissions,getRemissions, getLimitOrnamentales } from '../../../services/storage';
import { ValidateSafepassange } from '../../elements/ValidateFormComponent';
import { Tooltip } from '@material-ui/core';
import { DropDowncomponent} from '../../elements/DropDowncomponent';
import { DirectionsModal } from '../../modals/DirectionsModal'


const dataForm ={
    salvoconducto:"0",
    oficina:"",
    solicitud:"",
    fecha_solicitud:"",
    depexp:"",
    munexp:"",
    munexpId:"",
    regional:"",
    id:"",
    razonSocial:"",
    permissions:"",
    medida:"",
    medidaId:0,
    phone:"",
    address:"",
    email:"",
    otorgamiento:"",
    expOtor:"",
    numPro:"",
    expPro:"",
    ultNoti:"",
    vencimiento:"",
    kgsAut:"",
    kgsDis:"",
    kgsMv:"",
    kgsMov:0,
    undDis:"",
    undMov:"",
    fecha_trans:"",
    maritimo:false,
    mar_matri:"",
    fluvial:false,
    flu_matri:"",
    aereo:false,
    nom_aero:"",
    terrestre:false,
    doc_terres:"",
    nom_terres:"",
    placa_terres:"",
    tipo_terres:"",
    munori:"",
    munoriId:"",
    deporg:"",
    mundes:"",
    mundesId:"",
    depdes:"",
    embarque:"",
    cuenca:"",
    observaciones:"",
    rso_prm_rem:0,
    tip_rem:0
}

const errorModel = {
    transport:{state:false,msg:""},
    depexp:{state:false,msg:""},
    depori:{state:false,msg:""},
    depdes:{state:false,msg:""},
    munexp:{state:false,msg:""},
    munori:{state:false,msg:""},
    mundes:{state:false,msg:""},
    embarque:{state:false,msg:""},
    list:{state:false,msg:""},
    }

const errorEspecies = {
    nomcomun:{state:false,msg:"Debe elegir un nombre commun "},
    vol:{state:false,msg:"Debe agregar un volumen"},
    pre:{state:false,msg:"Debe elegir la forma de presentación"},
}

//const medida = [{pos:1,name:"Kilogramos"},{pos:2,name:"Alevinos"},{pos:3,name:"Unidades"}];
const medida = [{pos:1,name:"Kilogramos"},{pos:2,name:"Unidades Cultivo"},{pos:3,name:"Unidades Vivas"}];
//const medidaCultivo = [{pos:1,name:"Kilogramos"},{pos:2,name:"Alevinos"}];

const formReducer = (state,event)=>{

    return{
        ...state,
        [event.name]:event.value
    }
}

export function SafePassageComponent(props){

    //#region variables
    const [formData,setFormData] = useReducer(formReducer,dataForm);
    const [validateError,setValidateError] = useState(errorModel);
    const [userId,setUserId] = useState("");
    const [userToken,setUserToken] = useState("");
    const [userWeb,setUserWeb] = useState("");
    const [resolutionId,setResolutionId] = useState(0);
    const [resolutionType,setResolutionType] = useState(0);
    const [resolType,setResolType] = useState(0);
    const [resolutionProvitional,setResolutionProvitional] = useState({});
    const [counterListSpecies,setCounterListSpecies ] = useState(0)
    const [sendingForm,setSendingForm ] = useState(false);
    const [completeForm,setCompleteForm ] = useState(false)
    const [safePassange,setSafePassange] = useState(0);
    const [directionRsoState,setDirectionRsoState] = useState(false);
    const [directionRsoShow,setDirectionRsoShow] = useState(false);
    const [permissions,setPermissions] = useState([]);
    const [permissionsSelected,setPermissionsSelected] = useState({});
    const [directionRso,setDirectionRso] = useState([]);
    const [listResolutionsDir,setListResolutionsDir] = useState([]);
    const [listResolutionsSelectedDir,setListResolutionsSelectedDir] = useState([]);

    //modal especies
    const [modalSpecies,setModalSpecies] = useState(false);
    const [modalTransport,setModalTransport] = useState(false);
    const [modalResolutions,setModalResolutions] = useState(false);
    const [modalResolutionsUnits,setModalResolutionsUnits] = useState(false);
    const [modalResolutionsUnitsCom,setModalResolutionsUnitsCom] = useState(false);
    const [resolutionsButtons,setResolutionsButtons] = useState([]);
    const [listSpeciesResolutionsAll,setListSpeciesResolutionsAll] = useState([]);
    const [listResPermision,setListResPermision] = useState(0);
    const [listResolutionsRemissions,setListResolutionsRemissions] = useState([]);
    const [listSpeciesResolutions,setListSpeciesResolutions] = useState([]);
    const [unidSelected,setUnidSelected] = useState("");
    const [listVedas,setListVedas] = useState([]);
    const [LimitOrna,setLimitOrna] = useState(0);
    const [typeRemission,setTypeRemission] = useState([]);

    //modal inputs
    const [selectecCodeSpecieSend,setSelectecCodeSpecieSend] = useState('');
    const [selectecCodeSpecie,setSelectecCodeSpecie] = useState('');
    const [selectedNameSpecie,setSelectedNameSpecie] = useState('');
    const [selectedScientySpecie,setSelectedScientySpecie] = useState('');
    const [selectedMovSpecie,setSelectedMovSpecie] = useState(0);
    const [selectedTypeSpecie,setSelectedTypeSpecie] = useState('');
    const [selectedTypeSpecieId,setSelectedTypeSpecieId] = useState('');
    //const [selectedCode2Specie,setSelectedCode2Specie] = useState('1');//*/
    const [listSelecteSpecies,setListSelecteSpecies] = useState([]);
    const [listSelecteDirections,setListSelecteDirections] = useState([]);
    const [listPresentationSpeciesBase,setListPresentationSpeciesBase] = useState([]);
    const [listPresentationSpecies,setListPresentationSpecies] = useState([]);
    const [validateEspecies,setValidateEspecies ] = useState(errorEspecies);
    
    //deparments list
    const [listDeparments,setListDeparments] = useState([]);
    const [listDeparmentsShowInputOrg,setListDeparmentsShowInput] = useState([]);
    const [listDeparmentsShowInputExp,setListDeparmentsShowInputExp] = useState([]);
    const [selectedDeparmentOri,setSelectedDeparmentOri] = useState('');
    const [selectedDeparmentExp,setSelectedDeparmentExp] = useState('');
    const [listDeparmentsShowInputDes,setListDeparmentsShowInputDes] = useState([]);
    const [selectedDeparmentDes,setSelectedDeparmentDes] = useState('');
    const [errorVolumen,setErrorVolumne ] = useState(false); 

    //municipalities list
    const [listMunicipalities,setListMunicipalities] = useState([]);
    const [showLMuniOriBase,setshowLMuniOriBase] = useState([]);
    const [showLMuniExpBase,setshowLMuniExpBase] = useState([]);
    const [showLMuniOriCurrent,setshowLMuniOriCurrent] = useState([]);
    const [showListMunicipalitiesExp,setShowListMunicipalitiesExp] = useState([]);
    const [selectedMunicipalityOri,setSelectedMunicipalityOri] = useState("");
    const [selectedMunicipalityExp,setSelectedMunicipalityExp] = useState("");
    const [showLMuniDesBase,setshowLMuniDesBase] = useState([]);
    const [showLMuniDesCurrent,setshowLMuniDesCurrent] = useState([]);
    const [selectedMunicipalityDes,setSelectedMunicipalityDes] = useState('');
    
    //modal transpor inputs
    //const [createDate,setCreateDate] = useState(4)
    const [currentDate,setCurrentDate] = useState("")
    const [lastDate,setLastDate] = useState("")
    const [maritimeCheck,setMaritimeCheck] = useState(false);
    const [maritimeValue,setMaritimeValue] = useState("");
    const [riverCheck,setRiverCheck] = useState(false);
    const [riverValue,setRiverValue] = useState("");
    const [aerialCheck,setAerialCheck] = useState(false);
    const [aerialValue,setAerialValue] = useState("");
    const [noApplicable,setNoApplicable] = useState(false);
    const [landCheck,setLandCheck] = useState(false);
    const [landDocValue,setLandDocValue] = useState("");
    const [landNameValue,setLandNameValue] = useState("");
    const [landPlateValue,setLandPlateValue] = useState("");
    const [landTypeValue,setLandTypeValue] = useState("");
    
    
    let history = useHistory()

    //#endregion

    const callGoBack =  ()=>{
        setSessionSafe();
        history.goBack();
    }

    //#region deparmanet events
    const focusInDeparmentExp = ()=>{

        setListDeparmentsShowInputExp(listDeparments);
    }

    const focusInDeparmentOri = ()=>{
        setListDeparmentsShowInput(listDeparments);
    }

    const focusInDeparmentDes = ()=>{
        setListDeparmentsShowInputDes(listDeparments);
    }
    
    const changeDeparmentExp = async (text)=>
    {
        console.log(" change data text");

        setSelectedDeparmentExp(text);

        let result = listDeparments.filter((val)=>{
            if(val.DEP_NOM.toLowerCase().includes(text.toLowerCase())){ return val; }
            else{ return false; }
        }); 
        
        setListDeparmentsShowInputExp(result)
        setFormData({name:"munexp",value:"",input:null})
    }

    const changeDeparmentOri = async (text)=>
    {
        console.log(" change dep ori");

        setSelectedDeparmentOri(text);

        let result = listDeparments.filter((val)=>{
            if(val.DEP_NOM.toLowerCase().includes(text.toLowerCase())){ return val; }
            else{ return false; }
        }); 
        
        setListDeparmentsShowInput(result)
        setFormData({name:"depori",value:"",input:null})
    }

    const changeDeparmentDes = async (text)=>{
        setSelectedDeparmentDes(text);

        let result = listDeparments.filter((val)=>{
            if(val.DEP_NOM.toLowerCase().includes(text.toLowerCase())){ return val; }
            else{ return false; }
        }); 

        setListDeparmentsShowInputDes(result)
        setFormData({name:"depdes",value:"",input:null})
    }

    const selectDeparmentExp = (data)=>{

        setSelectedDeparmentExp(data.DEP_NOM)
        setSelectedMunicipalityExp("")
        dataForm.munexp = "";
        setFormData({name:"depexp",value:data.DEP_COD,input:null})

        let municipalities = listMunicipalities.filter((val)=>{

            if(val.DEP_COD===data.DEP_COD){ return val; }
            else{ return false; }

        })//*/

        setshowLMuniExpBase(municipalities);
    }

    const selectDeparmentOri = (data)=>{
        
        setSelectedDeparmentOri(data.DEP_NOM)
        setSelectedMunicipalityOri("")
        dataForm.munori = "";
        setFormData({name:"depori",value:data.DEP_COD,input:null})

        let municipalities = listMunicipalities.filter((val)=>{

            if(val.DEP_COD===data.DEP_COD){ return val; }
            else{ return false; }

        })//*/

        setshowLMuniOriBase(municipalities);
    }

    const selectDeparmentDes = (data)=>{

        setSelectedDeparmentDes(data.DEP_NOM)
        setSelectedMunicipalityDes("")
        dataForm.mundes = "";
        setFormData({name:"depdes",value:data.DEP_COD,input:null})

        let municipalities = listMunicipalities.filter((val)=>{

            if(val.DEP_COD===data.DEP_COD){ return val; }
            else{ return false; }
        })//*/

        setshowLMuniDesBase(municipalities);
    }

    //#endregion

    //#region municipality events
    const focusInMunicipalityExp = ()=>{

        setShowListMunicipalitiesExp(showLMuniExpBase);
    }

    const focusInMunicipalityOrg = ()=>{
        setshowLMuniOriCurrent(showLMuniOriBase);
    }

    const focusInMunicipalityDes = () =>{
    
        setshowLMuniDesCurrent(showLMuniDesBase);
    }

    const changeMunicipalityExp = async (text) =>{

        setSelectedMunicipalityExp(text);

        let result = showLMuniExpBase.filter((val)=>{

            if(val.MUN_NOM.toLowerCase().includes(text.toLowerCase())){ return val; }
            else{ return false; }
        })//*/

        setShowListMunicipalitiesExp(result)
        setFormData({name:"munexp",value:"",input:null})
    }

    const changeMunicipalityOri = async (text) =>{

        setSelectedMunicipalityOri(text);

        let result = showLMuniOriBase.filter((val)=>{

            if(val.MUN_NOM.toLowerCase().includes(text.toLowerCase())){ return val; }
            else{ return false; }
        })//*/

        setshowLMuniOriCurrent(result)
        setFormData({name:"munori",value:"",input:null})
        setFormData({name:"oficina",value:"",input:null})
        setFormData({name:"cuenca",value:"",input:null})
    }

    const selectMunicipalityExp = (data)=>
    {
        setSelectedMunicipalityExp(data.MUN_NOM);

        setFormData({name:"munexp",value:data.MUN_COD,input:null})
        //setFormData({name:"oficina",value:data.OFI_NOM,input:null})
        //setFormData({name:"cuenca",value:data.CUE_NOM,input:null})
        //setshowLMuniExpCurrent([]);
    }

    
    const selectMunicipalityOrg = (data)=>
    {
        setSelectedMunicipalityOri(data.MUN_NOM);

        let cuenca = data.CUE_NOM;

        if(data.CUE_NOM===""){ cuenca = "No aplica"; }

        setFormData({name:"munori",value:data.MUN_COD,input:null})
        setFormData({name:"oficina",value:data.OFI_NOM,input:null})
        setFormData({name:"cuenca",value:cuenca,input:null})
        setshowLMuniOriCurrent([]);
    }
    
    const changeMunicipalityDes = (text) =>
    {
        setSelectedMunicipalityDes(text);

        
        let result = listMunicipalities.filter((val)=>{

                if(val.MUN_NOM.toLowerCase().includes(text.toLowerCase())){ return val; }
                else{ return false; }
            })//*/

            
            setFormData({name:"mundes",value:"",input:null});
    }

    const selectMunicipalityDes = (data)=>
    {
        setSelectedMunicipalityDes(data.MUN_NOM);
        setFormData({name:"mundes",value:data.MUN_COD,input:null})
        setshowLMuniDesCurrent([]);
    }

    //#endregion

    //#region species events
    const showModal = () =>{

        console.log("Show modal esepcies",{cuenca:formData.cuenca,municipio:formData.munori,depto:formData.depori});        
        
        let message = "" 
        
        
        if(formData.depori=== undefined ){  message = "Debe seleccionar departamento de origen"; }
        if(formData.munori=== "" ){  message = "Debe seleccionar municipio de origen"; }
        
        if(message!==""){ 
            commonMsg("Requerimientos previo",message, msgIcons.warning); 
            return;
        }

        if(!modalSpecies){ updateListSpecies(); }

        setModalSpecies(!modalSpecies);

        if(modalSpecies)
        {
            setSelectecCodeSpecieSend('');
            setSelectecCodeSpecie('');
            setSelectedNameSpecie('');
            setSelectedScientySpecie('');
            setSelectedMovSpecie(0);
            setSelectedTypeSpecie('');
            setSelectedTypeSpecieId('');
        }
    }

    const changeTextSpeciesCode = async (text) =>{

        setSelectecCodeSpecie(text);
        
            let list = listSpeciesResolutionsAll.filter((ref)=>
            {
                if(ref.ESP_COD_INT.toLowerCase().includes(text.toLowerCase()) && ref.RSO===resolutionId ){ return ref; }
                else{ return false; }
            });

            setListSpeciesResolutions([...list]);
    }

    const changeTextSpecies = async (text) =>{

        setSelectedNameSpecie(text);
        
            let list = listSpeciesResolutionsAll.filter((ref)=>
            {

                if(ref.ESP_NOM_JSO.toLowerCase().includes(text.toLowerCase()) && ref.RSO===resolutionId ){ return ref; }
                else{ return false; }
            });

            setListSpeciesResolutions([...list]);
    }

    const selectSpecie = (data) => {

        setSelectecCodeSpecieSend(data.ESP)
        setSelectecCodeSpecie(data.ESP_COD_INT);
        setSelectedNameSpecie(data.ESP_NOM_JSO);
        setSelectedScientySpecie(data.ESP_NOM_CIE);
    }

    const changeVolSpecie = (text) =>{

        if(text<0){ text*=-1; }

        setSelectedMovSpecie(Math.round(text));
    }

    const focusInSpeciesFromat = () =>{

        setListPresentationSpecies(listPresentationSpeciesBase);
    }

    const changeTextSpeciesFormat = async (text) =>{

        setSelectedTypeSpecie(text);

            let list = listPresentationSpeciesBase.filter((ref)=>
            {
                if(ref.NAME.toLowerCase().includes(text.toLowerCase())){ return ref; }
                else{ return false; }
            });

            setListPresentationSpecies(list);
    }

    const selectSpecieFormat = (data) => {

        setSelectedTypeSpecie(data.NAME);
        setSelectedTypeSpecieId(data.ID);
    }

    const saveSpecie = () =>{

        console.log(" salvar escpecies y calcular ");

        //validateEspecies,setValidateEspecies
        let currentValidate = validateEspecies;
        let confirm = true;

        if(selectedNameSpecie==="")
        {
            currentValidate.nomcomun.state = true;
            confirm = false;
        }else{
            currentValidate.nomcomun.state = false;
        }

        if(Number(selectedMovSpecie)===0 || selectedMovSpecie==="" )
        {
            currentValidate.vol.state = true;
            confirm = false;
        }else{
            currentValidate.vol.state = false;
        }

        if(selectedTypeSpecieId==="")
        {
            currentValidate.pre.state = true;
            confirm = false;
        }else{
            currentValidate.pre.state = false;
        }
    
        const newList = {...validateEspecies,state:""};

        setValidateEspecies(newList);

        if(!confirm){ return; }

        const currentList = listSelecteSpecies;
        console.log(currentList);

        console.log(selectecCodeSpecie);

        const currentData = [{code:selectecCodeSpecie,esp:selectecCodeSpecieSend,name:selectedNameSpecie,sci:selectedScientySpecie,vol:Number(selectedMovSpecie),
            pre:selectedTypeSpecieId,pre_nom:selectedTypeSpecie,pos:counterListSpecies
            }]    

        //filter
        const specie = currentList.filter(item=>item.esp===selectecCodeSpecie);  
        let addData = true;

        if(specie.length >0 && JSON.stringify([specie[0].esp,specie[0].pre])===JSON.stringify([currentData[0].esp,currentData[0].pre])){ addData = false; }

        if(addData)
        {
            let currentVol = Number(formData.kgsMov);

            currentVol+= Number(selectedMovSpecie);
            setFormData({name:"kgsMov",value:Number(currentVol),input:null})

            const result = [...currentList,...currentData]
            setListSelecteSpecies(result);
            setCounterListSpecies(counterListSpecies+1);//*/

            console.log(" data previus  ");
            
            
            if((Number(resolutionProvitional.PRM) !== 3 || (Number(resolutionProvitional.PRM)===3 && Number(LimitOrna) === 0 )  ) &&  formData.kgsDis <= currentVol ){ 
                console.log(" Entra condicional  ");
                setErrorVolumne(true);} 
            }


        showModal();
    }

    const removeSpecie = (id) =>{

        console.log("remove especie");
        console.log(id);

        let volume = 0;

        const currentList = listSelecteSpecies.filter((ref)=>
        {
            if(ref.pos!==id){ return ref;}
            else{ 
                console.log("referencia para el que existe")
                console.log(ref)
                volume = ref.vol;
                return false; 
            }
        });

        let currentVol = Number(formData.kgsMov);
            
        currentVol-= Number(volume);
        setFormData({name:"kgsMov",value:Number(currentVol),input:null})
        
        if(formData.kgsDis >= currentVol ){ setErrorVolumne(false);} 

        setListSelecteSpecies(currentList);
    }
    
    //#endregion

    //#region others
    const changeInput = event =>{

        let valueData =  event.target.value;

        if(event.target.type=== "checkbox"){ valueData = event.target.checked }
        
        setFormData({name:event.target.name,value:valueData,input:event.target})
    }
    
    const showModalTranport = ()=>{
        setModalTransport(!modalTransport);
    }

    const changeTrasport = (event,state) =>{

        if(event==="maritime"){ setMaritimeCheck(state); }
        if(event==="river"){ setRiverCheck(!riverCheck); }
        if(event==="aerial"){ setAerialCheck(!aerialCheck); }
        if(event==="land"){ setLandCheck(!landCheck); }
        if(event==="noApplicable")
        { 
            setNoApplicable(state) 
            setMaritimeCheck(false);
            setRiverCheck(false);
            setAerialCheck(false);
            setLandCheck(false);
        }
    }

    const updateTranportValues = event =>{

        if(event.target.name==="mat_maritimo"){ setMaritimeValue(event.target.value); }
        if(event.target.name==="mat_fluvial"){ setRiverValue(event.target.value); }
        if(event.target.name==="nom_aereo"){ setAerialValue(event.target.value); }
        if(event.target.name==="doc_terrestre"){ setLandDocValue(event.target.value); }
        if(event.target.name==="nom_terrestre"){ setLandNameValue(event.target.value); }
        if(event.target.name==="placa_terrestre"){ setLandPlateValue(event.target.value); }
        if(event.target.name==="tipo_terrestre"){ setLandTypeValue(event.target.value); }
    }

    const saveTransport = ()=>{
        
        let htmlData = "";

        if(maritimeCheck && maritimeValue!=="" )
        {
            console.log(maritimeValue);

            htmlData += "<div class='row-transport' ><label>Transporte maritimo</label><br/>Matricula Transporte: <span class='transport-preview' >"
                    + maritimeValue+"</span></div>";

            setFormData({name:"maritimo",value:maritimeCheck,input:null})
            setFormData({name:"mar_matri",value:maritimeValue,input:null})

            
        }else{
            setMaritimeCheck(false);
            setFormData({name:"maritimo",value:false,input:null})
        }

        if(riverCheck && riverValue !=="" )
        {
            console.log(riverValue)

            setFormData({name:"fluvial",value:riverCheck,input:null})
            setFormData({name:"flu_matri",value:riverValue,input:null})
            htmlData += "<div class='row-transport' ><label>Transporte Fluvial</label><br/>Matricula Transporte: <span class='transport-preview' >"
                    +riverValue+"</span></div>";

            console.log(htmlData);
        }else{
            setRiverCheck(false);
            setFormData({name:"fluvial",value:false,input:null})
        }

        if(aerialCheck && aerialValue!=="" )
        {
            setFormData({name:"aereo",value:aerialCheck,input:null})
            setFormData({name:"nom_aero",value:aerialValue,input:null})
            htmlData += "<div class='row-transport' ><label>Transporte Aereo</label><br/>Nombre Aereolinea: <span class='transport-preview' >"
                    + aerialValue +"</span></div>";

        }else{
            setAerialCheck(false);
            setFormData({name:"aereo",value:false,input:null})
        }

        if(landCheck && landDocValue!=="" && landNameValue !=="" && landPlateValue !=="" && landTypeValue !=="" )
        {
            setFormData({name:"terrestre",value:landCheck,input:null})
            setFormData({name:"doc_terres",value:landDocValue,input:null})
            setFormData({name:"nom_terres",value:landNameValue,input:null})
            setFormData({name:"placa_terres",value:landPlateValue,input:null})
            setFormData({name:"tipo_terres",value:landTypeValue,input:null})

            htmlData += "<div class='row-transport' ><label>Transporte Terrestre</label><br/>Documento: <span class='transport-preview' >"
                    + landDocValue +"</span><br/>Nombre: <span class='transport-preview' >"
                    + landNameValue +"</span><br/>Placa: <span class='transport-preview' >"
                    + landPlateValue + "</span><br/>Tipo: <span class='transport-preview' >"
                    + landTypeValue + "</span><br/></div>";
        }else {
            setLandTypeValue("");
            setFormData({name:"terrestre",value:false,input:null})
        }

        commonConfirm({title:"Los datos de transporte son:",text:"<div class='modal-transport' >"+htmlData+"</div>"},confrimTransport);
    }

    const confrimTransport = () =>{
        setModalTransport(!modalTransport);
    } 

    const showModalDirections = ()=>setDirectionRsoShow(!directionRsoShow)

    const aceptDirections = (data) => {

        const proList = []

        for(let indexList in data.list)
        {
            let item = data.list[indexList];

            if(Number(listResPermision)===1 && item.cheked){proList.push({DIR:item.ID,MUN:0})
            }else if(Number(listResPermision)===2 && item.cheked){ proList.push({DIR:0,MUN:item.ID}) } 
        }

        console.log("720::: Direcciones data:::: ",data)

        setListResolutionsSelectedDir(data.list);
        setFormData({name:"rso_prm_rem",value:data.selecteRemission ,input:null})
        setListSelecteDirections(proList)
        
        showModalDirections()
    }

    const getResolutions = async (user) =>{

        let resolution = await getUserResolutions(user.USU_ID);

        if(resolution.count>0)
        {
            setModalResolutions(true);
            setResolutionsButtons(resolution.res)
            setListSpeciesResolutionsAll(resolution.res_esp);
            setListResolutionsDir(resolution.res_dir);
        }else{

            commonToast("No tiene resoluciones disponibles",msgIcons.warning)
            callGoBack();
        }

    }

    const selecteResolutionPrev = (item) =>{

        const filterPermissions =  permissions.filter((itemPer)=> Number(itemPer.TIP) === Number(item.PRM) );

        console.log(":::---- 749 ::::::selecte resolution:::: ",filterPermissions);

        console.log(item);

        setPermissionsSelected(filterPermissions[0])
        setResolutionProvitional(item);
        setModalResolutionsUnits(true);

        /*if(item.PRM==="1")
        { 
            setResolutionProvitional(item);
            setModalResolutionsUnitsCom(true);
        }
        else if(item.PRM+""==="3"){ selecteResolution(item,3); }
        else if(item.PRM+""==="2")
        { 
            setResolutionProvitional(item);
            setModalResolutionsUnits(true);            
        }//*/
    }

    const selectResolutionsUnit = (pos)=>{
        selecteResolution(resolutionProvitional,pos);
    }

    const selecteResolution = (item,units=1)=>
    {
        const formatList = JSON.parse( getFormatPresentation());
        const filterFormat = formatList.filter(format=>format.MED===units+"");
       
        const remissions = JSON.parse(getRemissions() || [])

        //CAN_DIR /TIP_REM
        const filterPermissions =  permissions.filter((itemPer)=>itemPer.TIP_REM!=="_" && Number(itemPer.TIP) === Number(item.PRM) );
        
        //PRM
        setResolutionType(item.PRM);
        setResolType(item.TIP)
        setListPresentationSpeciesBase(filterFormat);
        setResolutionId(item.ID);
        setDirectionRso(item.DIR_RSO)

        const currentRes = listSpeciesResolutionsAll.filter((val)=>{

            if(val.RSO===item.ID && Number(val.ESP_PRM)===Number(units) ){  return val; 
            }else{ return false; }
        });

        if(Number(item.CAN_DIR)>0 &&  filterPermissions.length>0){
            
            let currentDirs = listResolutionsDir.filter((dirs)=>Number(dirs.RSO)===Number(item.RSO_PAD));
            
            if(currentDirs.length===0){ currentDirs = listResolutionsDir.filter((dirs)=>Number(dirs.RSO)===Number(item.ID)) }

            setListResolutionsSelectedDir(currentDirs);
            setDirectionRsoState(true)

            const filterRemissions =  remissions.filter((itemPer)=>Number(itemPer.PRM)===Number (item.PRM)  );

            setFormData({name:"tip_rem",value:filterPermissions[0].TIP_REM ,input:null})
            setListResPermision(filterPermissions[0].TIP_REM)
            setListResolutionsRemissions(filterRemissions)
        }

        const unit = medida.filter(unit=>unit.pos===units);

        console.log(" show sta whit selecte resolution",{units,unit,currentRes,item});

        let volume = 0; 
        let valAutorizado = 0;
        let valMovilizado = 0;

        if(unit[0].pos===1)
        {  
            volume = item.KGS_DIS; 
            setUnidSelected("Kg.");  
            valAutorizado = item.KGS_AUT;
            valMovilizado = item.KGS_AUT- volume;
        
        }
        else if(unit[0].pos===2)
        {  
            volume = item.ALV_DIS; 
            setUnidSelected("Alevinos"); 
            valAutorizado = item.ALV_AUT;
            valMovilizado = item.ALV_AUT- volume;
        }
        else if(unit[0].pos===3)
        {  
            volume = item.UND_DIS; 
            setUnidSelected("Und."); 
            valAutorizado = item.UND_AUT;
            valMovilizado = item.UND_AUT - volume;
        }



        setListSpeciesResolutions(currentRes);

        setFormData({name:"permissions",value:item.PRM_NOM ,input:null})
        setFormData({name:"medida",value:unit.length>0 ? unit[0].name :"",input:null})
        setFormData({name:"medidaId",value:units,input:null})
        setFormData({name:"otorgamiento",value:item.NUM_OTO,input:null})
        setFormData({name:"expOtor",value:item.FCH_EXP_OTO,input:null})
        setFormData({name:"numPro",value:item.NUM,input:null})
        setFormData({name:"expPro",value:item.FCH_EXP_PRR,input:null})
        setFormData({name:"ultNoti",value:item.FCH_NOT,input:null})
        setFormData({name:"vencimiento",value:item.FCH_VEN,input:null})
        setFormData({name:"kgsAut",value:valAutorizado,input:null})//*/
        setFormData({name:"kgsDis",value:volume,input:null})//*/
        setFormData({name:"kgsMv",value:valMovilizado ,input:null})//*/

        setModalResolutions(false);
        setModalResolutionsUnits(false)
        setModalResolutionsUnitsCom(false);
    }

    const sendForm = () => {

        setSendingForm(true);


        

        if((Number(resolutionProvitional.PRM) !== 3 || (Number(resolutionProvitional.PRM)===3 && Number(LimitOrna) === 0 )  ) && 
            formData.kgsDis <= formData.kgsMov )
        {
            commonMsg("Volumen Superado","El volumen a movilizar supera la cantidad permitida.", msgIcons.error);
            setSendingForm(false);
            return;
        }


        const resultValidate  = ValidateSafepassange(formData,listSelecteSpecies,noApplicable)

        setValidateError(resultValidate.data);
        
        if(!resultValidate.continue){ 
            
            commonMsg("Campos faltantes","Debe agregar los campos faltantes ", msgIcons.error);
            
            setSendingForm(false); return; 
        }

        const formSend = {
            slv:{
                ent:userId,
                prm:resolutionType,
                fch_tra:formData.fecha_trans,
                tra_mar:formData.maritimo,
                tra_flu:formData.fluvial,
                tra_ter:formData.terrestre,
                tra_aer:formData.aereo,
                mun_exp:formData.munexp, 
                mun_ori:formData.munori, 
                mun_des:formData.mundes,
                sit_emb:formData.embarque,
                med:formData.medidaId,
                mat_mar:formData.mar_matri,
                mat_flu:formData.flu_matri,
                aer:formData.nom_aero,
                con_doc:formData.doc_terres,
                con_nom:formData.nom_terres,
                veh_pla:formData.placa_terres,
                veh_tip:formData.tipo_terres,
                fir:"",
                obs:formData.observaciones,
                ACC_TOK:userToken,
                usr_web:userWeb,
                tra_napl:noApplicable,
                rso_prm_rem:formData.rso_prm_rem,
                tip_rem:formData.tip_rem
            },
            slv_esp:listSelecteSpecies,
            slv_dir:listSelecteDirections
        }

        console.log("formSend",{formSend} );


        sendSafepassange(formSend)
        .then(data=>{

            if(data.state){
                
                //props.setAuth(true);
                commonToast("Salvoconducto Validado",msgIcons.success);
                setCompleteForm(true);
                setSafePassange(data.id);
                //history.push('/menu') 
            }else{
                commonMsg("Error al enviar",data.mng, msgIcons.error);
                setSendingForm(false);
            }
        })
        .catch(error=>{
            console.log("error")
            console.log(error)
            setSendingForm(false);
        });//*/
    }

    const printSafe = () =>{

        getDocument(safePassange)
        .then(data=>{

            const date = new Date();
            const nameDate = date.getDate()+""+(date.getMonth()+1)+""+date.getFullYear();

            if(data.state){ GenerateDocument(data.pdf,nameDate); }
            else{
                commonMsg("Error al intentar generar documento",data.mng, msgIcons.error)
            }//*/

        })
        .catch(error=>{

        })//*/
        
    }

    const updateListSpecies = ()=>{
        
        let listPro = [];
        let listValidate = []

        for(let inVedas in  listVedas){
            let itemVeda = listVedas[inVedas];
            let dateValid = validateVedaDate(listVedas[inVedas].FCH_DES,listVedas[inVedas].FCH_HAS)

            if(dateValid){ listValidate.push(itemVeda) }
        }

        console.log(" list validate date",listValidate);

        for(let iSpecies in listSpeciesResolutions)
        {
            let itemSpecie = listSpeciesResolutions[iSpecies];
            itemSpecie.disable = false;

            
            listPro.push({
                    ESP:itemSpecie.ESP,
                    ESP_COD_INT:itemSpecie.ESP_COD_INT,
                    ESP_NOM_CIE:itemSpecie.ESP_NOM_CIE,
                    ESP_NOM_COM:itemSpecie.ESP_NOM_COM,
                    ESP_NOM_JSO:itemSpecie.ESP_NOM_JSO,
                    ESP_PRM:itemSpecie.ESP_PRM,
                    ID:itemSpecie.ID,
                    RSO:itemSpecie.RSO,
                    disabled:false,
                    type:""
            })

            const inPro = (listPro.length-1);

            for(let inVedas in  listValidate){
                    
                let itemV = listValidate[inVedas];

                    listPro[inPro].AMB_NOM = itemV.AMB_NOM;
                    listPro[inPro].DEP_NOM = itemV.DEP_NOM;
                    listPro[inPro].MUN_NOM = itemV.MUN_NOM;
                    listPro[inPro].CUE_NOM = itemV.CUE_NOM;
                    listPro[inPro].FCH_DES = itemV.FCH_DES;
                    listPro[inPro].FCH_HAS = itemV.FCH_HAS;


                    //console.log(" datos de la espacie::: ",{rs:itemV.ESP,local:listPro[inPro].ID,rso:resolType});

                    //cosumo
                    if(Number(itemV.TIP)===1 && (Number(resolutionType)===1 || Number(resolutionType)===3) && Number(itemV.AMB)===3  && !listPro[inPro].disabled 
                        && itemV.MUN_NOM==="" && itemV.DEP_NOM==="" && itemV.CUE_NOM === formData.cuenca && itemV.ESP === 0 )
                    {
                        listPro[inPro].disabled = true;

                    }else if(Number(itemV.TIP)===1 && (Number(resolutionType)===1 || Number(resolutionType)===3) && Number(itemV.AMB)===5 && !listPro[inPro].disabled 
                        && itemV.MUN===formData.munori && itemV.DEP_NOM==="" && itemV.CUE_NOM === formData.cuenca && itemV.ESP === 0 )
                    {
                        listPro[inPro].disabled = true;
                    }else if(Number(itemV.TIP)===1 && (Number(resolutionType)===1 || Number(resolutionType)===3) && Number(itemV.AMB)===4 && !listPro[inPro].disabled 
                        && itemV.DEP===formData.depori && itemV.MUN===0 && itemV.CUE_NOM === formData.cuenca && itemV.ESP === 0 )
                    {
                        listPro[inPro].disabled = true;
                    }else if(Number(itemV.TIP)===1 && (Number(resolutionType)===1 || Number(resolutionType)===3) && Number(itemV.AMB)===2 && !listPro[inPro].disabled 
                        && itemV.DEP===0 && itemV.MUN===formData.munori && itemV.CUE_NOM === "" && itemV.ESP === 0 )
                    {
                        listPro[inPro].disabled = true;
                    }else if(Number(itemV.TIP)===1 && (Number(resolutionType)===1 || Number(resolutionType)===3) && Number(itemV.AMB)===1 && !listPro[inPro].disabled 
                        && itemV.DEP=== formData.depori && itemV.MUN===0 && itemV.CUE_NOM === "" && itemV.ESP === 0 )
                    {
                        listPro[inPro].disabled = true;
                    }
                    else if(Number(itemV.TIP)===1 && (Number(resolutionType)===1 || Number(resolutionType)===3) && Number(itemV.AMB)===0 && !listPro[inPro].disabled 
                        && itemV.DEP=== 0 && itemV.MUN===0 && itemV.CUE_NOM === "" && itemV.ESP === 0 )
                    {
                        listPro[inPro].disabled = true;
                    }
                    //sin definir 
                    if(itemV.TIP==="1" && Number(resolutionType)!==2 && Number(itemV.AMB)===3 && !listPro[inPro].disabled && itemV.MUN_NOM==="" 
                        && itemV.DEP_NOM==="" && itemV.CUE_NOM === formData.cuenca  && itemV.ESP === listPro[inPro].ESP )
                    {
                        listPro[inPro].disabled = true;
                        listPro[inPro].type="1";

                    }else if(itemV.TIP==="_" && Number(resolutionType)!==2 && Number(itemV.AMB)===5 && !listPro[inPro].disabled && itemV.MUN===formData.munori 
                        && itemV.DEP_NOM==="" && itemV.CUE_NOM === formData.cuenca && itemV.ESP === 0 )
                    {
                        listPro[inPro].disabled = true;
                        listPro[inPro].type="1";
                    }else if(itemV.TIP==="_" && Number(resolutionType)!==2 && Number(itemV.AMB)===4 && !listPro[inPro].disabled && itemV.DEP===formData.depori 
                        && itemV.MUN===0 && itemV.CUE_NOM === formData.cuenca && itemV.ESP === 0 )
                    {
                        listPro[inPro].disabled = true;
                        listPro[inPro].type="1";
                    }else if(itemV.TIP==="_" && Number(resolutionType)!==2 && Number(itemV.AMB)===2 && !listPro[inPro].disabled && itemV.DEP===0 && 
                        itemV.MUN===formData.munori && itemV.CUE_NOM === "" && itemV.ESP === 0 )
                    {
                        listPro[inPro].disabled = true;
                        listPro[inPro].type="1";
                    }else if(itemV.TIP==="_" && Number(resolutionType)!==2 && Number(itemV.AMB)===1 && !listPro[inPro].disabled && itemV.DEP=== formData.depori 
                        && itemV.MUN===0 && itemV.CUE_NOM === "" && itemV.ESP === 0 )
                    {
                        listPro[inPro].disabled = true;
                        listPro[inPro].type="1";
                    }else if(itemV.TIP==="_" && Number(resolutionType)!==2 && Number(itemV.AMB)===0 && !listPro[inPro].disabled && itemV.DEP=== 0 
                        && itemV.MUN===0 && itemV.CUE_NOM === "" && itemV.ESP === 0 )
                    {
                        listPro[inPro].disabled = true;
                        listPro[inPro].type="1";
                    }
            }
        }

        console.log(" data ",listPro)

        setListSpeciesResolutions([...listPro])
    }

    const validateVedaDate = (dateInit,dateEnd)=>{

        const trasDate = formData.fecha_trans.split("-");
        const dateInitList = dateInit.split("-");
        const dateEndList = dateEnd.split("-");
        

        let dateSeleted = new Date();
        let dateVedaInit = new Date();
        let dateVedaEnd = new Date(dateEnd);

        dateSeleted.setFullYear(trasDate[0])
        dateSeleted.setMonth(Number(trasDate[1])-1)
        dateSeleted.setDate(Number(trasDate[2]))
        dateSeleted.setHours(0,0,0,0);

        dateVedaInit.setFullYear(dateInitList[0])
        dateVedaInit.setMonth(Number(dateInitList[1])-1)
        dateVedaInit.setDate(Number(dateInitList[2]))
        dateVedaInit.setHours(0,0,0,0);

        dateVedaEnd.setFullYear(dateEndList[0])
        dateVedaEnd.setMonth(Number(dateEndList[1])-1)
        dateVedaEnd.setDate(Number(dateEndList[2]))
        dateVedaEnd.setHours(0,0,0,0);
                
        if(dateSeleted.getTime()>= dateVedaInit.getTime() && dateSeleted.getTime()<= dateVedaEnd.getTime() ){ return true;}
        
        return false;
    }

    //#endregion

    useEffect( ()=>{

        (async () =>{
            
            let listDeparments = JSON.parse(getDeptos());
            let listMunicipalities = JSON.parse(getMunicipality());
            let daysValidate = getValidDays();
            let listVedas = JSON.parse(getVedas()); 
            let perList = JSON.parse(getPermissions() || [])

            setPermissions(perList)
            setListDeparments(listDeparments);
            setListMunicipalities(listMunicipalities);
            setListVedas(listVedas);
            setLimitOrna(getLimitOrnamentales)

            let data = new Date();
            let lastdata = new Date();
            let year = data.getFullYear();
            let day = data.getDate();
            let month = data.getMonth()+1
            let lastDates =  new Date( lastdata.setDate( data.getDate()+Number(daysValidate) ));
            let lastYear = lastDates.getFullYear();
            let lastDay = lastDates.getDate();
            let lastMonth = lastDates.getMonth()+1;
            
            if(month<10){ month = "0"+ month;}
            if(day<10){ day = "0"+day;}

            if(lastMonth<10){ lastMonth = "0"+ lastMonth;}
            if(lastDay<10){ lastDay = "0"+lastDay;}
        
            let current = year+"-"+month+"-"+day;

            const user =  JSON.parse(getUser());

            setUserId(user.USU_ID);
            setUserToken(user.ACC_TOK);
            setUserWeb(user.USU_WEB_ID)

            setCurrentDate(year+"-"+month+"-"+day)
            setLastDate(lastYear+"-"+lastMonth+"-"+lastDay);

            setFormData({name:"razonSocial",value:user.USU_NOM,input:null})
            setFormData({name:"id",value:user.USU_DOC,input:null})
            setFormData({name:"phone",value:user.USU_TLF,input:null})
            setFormData({name:"address",value:user.USU_DIR,input:null})
            setFormData({name:"email",value:user.USU_EML,input:null})
            setFormData({name:"fecha_solicitud",value:current,input:null})
            setFormData({name:"fecha_trans",value:current,input:null})

            getResolutions(user)

            let safe = getSessionSafe();

            if(safe!=="true"){ callGoBack(); }
            
        })();
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return(
        <div className="parent-component" >
            <button className="btn btn-primary return return-margin" onClick={callGoBack} title="Regresar a la vista anterior" > 
                <GetICon type={"fas"} icon={"arrow-alt-circle-left"} size={"lg"} /> &nbsp;
                Regresar
            </button><br/><br/>
            <h2 style={{textAlign:'center'}} >Salvoconducto</h2><br/>
            <div className="safe-form-parent" > 
                <h3 style={{textAlign:'center',fontSize:"24px"}} >Datos Generales</h3><br/>
                <hr/>
                <div className="safe-content" >
                    <div className="row" >
                        <div className="col-12 col-md-2" >
                            <label className="label-full" >Identificación</label><br/>
                                <input type="text" name="id" className="form-control" defaultValue={formData.id} disabled="disabled" />
                        </div>
                        <div className="col-12 col-md-4" >
                            <label className="label-full" >Razón Social</label><br/>
                            <input type="text" name="razonSocial" className="form-control" defaultValue={formData.razonSocial} disabled="disabled" />
                        </div>
                        <div className="col-12 col-md-3" >
                            <label className="label-full" >Tipo de permiso</label><br/>
                            <input type="text" name="permissions" className="form-control" defaultValue={formData.permissions} disabled="disabled" />
                        </div>
                        <div className="col-12 col-md-3" >
                            <label className="label-full" >Medida</label>
                            <input type="text" name="medida" className="form-control" defaultValue={formData.medida} disabled="disabled" />
                        </div>
                        <div className="col-12 col-md-2" >
                            <label className="label-full" >Teléfono</label><br/>
                                <input type="text" name="phone" placeholder="" className="form-control" defaultValue={formData.phone} disabled="disabled" />
                        </div>
                        <div className="col-12 col-md-5" >
                            <label className="label-full" >Dirección</label><br/>
                                <input type="text" name="terminal" placeholder="" className="form-control" defaultValue={formData.address} disabled="disabled"/>
                        </div>
                        <div className="col-12 col-md-5" >
                            <label className="label-full" >Email</label><br/>
                                <input type="text" name="terminal" placeholder="" className="form-control" defaultValue={formData.email} disabled="disabled" />
                        </div>
                        <div className="col-12 col-md-4" >
                            <label>Fecha de solicitud</label>
                            <input type="date" placeholder="" className="form-control" defaultValue={formData.fecha_solicitud}  disabled="disabled" />
                        </div>
                        <div className="col-12 col-md-4" >
                            <div className="parent-input-search"  >
                                <label className="label-full" >
                                    Departamento Exp *
                                    <Tooltip title={"Departamento de expedición"} arrow>
                                        <span>
                                        <GetICon type={"fas"} icon={"question-circle"} size={"sm"} /> &nbsp;
                                        </span>
                                    </Tooltip>
                                </label><br/>
                                <DropDowncomponent 
                                    selected={selectedDeparmentExp} 
                                    change={changeDeparmentExp}  
                                    list={listDeparmentsShowInputExp}
                                    select={selectDeparmentExp}
                                    state={validateError.depexp.state}
                                    msg={validateError.depexp.msg}
                                    focus={focusInDeparmentExp}
                                    format = {["DEP_NOM"]} 
                                    key="depexp"
                                    valueKey={"DEP_COD"}
                                    />
                            </div>
                        </div>
                        <div className="col-12 col-md-4" >
                            <div className="parent-input-search" >
                                <label className="label-full" >
                                    Municipio Exp *
                                    <Tooltip title={"Municipio de Expedición"} arrow >
                                    <span>
                                        <GetICon type={"fas"} icon={"question-circle"} size={"sm"} /> &nbsp;
                                    </span>
                                    </Tooltip>
                                </label><br/>
                                <DropDowncomponent 
                                    selected={selectedMunicipalityExp} 
                                    change={changeMunicipalityExp}  
                                    list={showListMunicipalitiesExp}
                                    select={selectMunicipalityExp}
                                    state={validateError.munexp.state}
                                    msg={validateError.munexp.msg}
                                    focus={focusInMunicipalityExp}
                                    format = {["MUN_NOM"]}
                                    basekey="munexp"
                                    valueKey={"MUN_COD"}
                                    />
                            </div>
                        </div>
                    </div><br/><br/>
                    <hr/>
                    <div className="row" >
                        <div className="col-12 col-md-4" >
                            <label className="label-full" >No. otorgamiento</label><br/>
                            <input type="text" name="terminal" className="form-control" defaultValue={formData.otorgamiento} disabled="disabled" />
                        </div>
                        <div className="col-12 col-md-4" >
                                <label>Exp. Otorgamiento</label>
                                <input type="date" name="expOtor" className="form-control" defaultValue={formData.expOtor} disabled="disabled" />
                        </div>
                        <div className="col-12 col-md-4" >
                            <label className="label-full" >No. Prórroga</label><br/>
                            <input type="text" name="terminal" className="form-control" defaultValue={formData.numPro} disabled="disabled" />
                        </div>
                        <div className="col-12 col-md-4" >
                                <label>Exp. Prórroga</label>
                                <input type="date" className="form-control" defaultValue={formData.expPro} disabled="disabled" />
                        </div>
                        <div className="col-12 col-md-4" >
                                <label>Últ. Notificación</label>
                                <input type="date" placeholder="" className="form-control" defaultValue={formData.ultNoti} disabled="disabled" />
                        </div>
                        <div className="col-12 col-md-4" >
                            <label>Fecha de vencimiento</label>
                            <input type="date" placeholder="" className="form-control" defaultValue={formData.vencimiento} disabled="disabled" />
                        </div>
                    </div><br/>
                    
                    <div className="row" >
                        <div className="col-12 col-md-3" >
                        <label className="label-full" >Volumen {unidSelected} Autorizado</label><br/>
                                <input type="text" placeholder="" className="form-control" defaultValue={formData.kgsAut} disabled="disabled" />
                        </div>
                        <div className="col-12 col-md-3" >
                            <label className="label-full" >Volumen {unidSelected} disponible</label><br/>
                                <input type="text" placeholder="" className="form-control" defaultValue={formData.kgsDis} disabled="disabled" />
                        </div>
                        <div className="col-12 col-md-3" >
                        <label className="label-full" >Volumen {unidSelected} movilizado</label><br/>
                                <input type="text" placeholder="" className="form-control" defaultValue={formData.kgsMv} disabled="disabled" />
                        </div>
                        <div className="col-12 col-md-3" >
                            <label className="label-full" >Volumen {unidSelected} a movilizar</label><br/>
                                <input type="text" placeholder="" className="form-control" value={formData.kgsMov} disabled="disabled" />
                        </div>

                    </div><br></br>
                    <div className="row" >
                        <div className="col-12 col-md-3" >
                            <label>
                                Fecha transporte * 
                                <Tooltip title={"Seleccionar la fecha de transporte"} arrow >
                                    <span>
                                    <GetICon type={"fas"} icon={"question-circle"} size={"sm"} onClick={ ()=>{ }  } /> &nbsp;
                                    </span>
                                </Tooltip>
                            </label>
                            <input type="date" name="fecha_trans" className="form-control" defaultValue={formData.fecha_trans} min={currentDate} max={lastDate} onChange={changeInput} />
                        </div>
                        <div className="col-12 col-md-5" >
                            <label>
                                Transporte *
                                <Tooltip title={"Debe seleccionar al menos un tipo de transporte"} arrow >
                                    <span>
                                    <GetICon type={"fas"} icon={"question-circle"} size={"sm"} onClick={ ()=>{  }  } /> &nbsp;
                                    </span>
                                </Tooltip>
                            </label><br/>
                            <div className="btn btn-info btn-add" onClick={showModalTranport} title="Agregar al menos un transporte"  >
                                <GetICon type={"fas"} icon={"truck"} size={"lg"} /> &nbsp;
                                Agregar
                            </div><br/>
                            <label className="check-custom" >
                                <input type="checkbox" name="maritimo" disabled="disabled" checked={formData.maritimo}  />
                                &nbsp; Marítimo
                                <span className='checkmark' >
                                    <GetICon type={"fas"} icon={"check"} size={"xs"} />
                                </span>
                            </label>&nbsp; &nbsp; 
                            <label className="check-custom" >
                                <input type="checkbox" name="fluvial" disabled="disabled" checked={formData.fluvial} />
                                &nbsp;Fluvial
                                <span className='checkmark' >
                                    <GetICon type={"fas"} icon={"check"} size={"xs"} />
                                </span>
                            </label>&nbsp; &nbsp; 
                            <label className="check-custom" >
                                <input type="checkbox" name="terrestre" disabled="disabled" checked={formData.terrestre}  />
                                &nbsp;Terrestre
                                <span className='checkmark' >
                                    <GetICon type={"fas"} icon={"check"} size={"xs"} />
                                </span>
                            </label>&nbsp; &nbsp; 
                            <label className="check-custom" >
                                <input type="checkbox" name="aereo" disabled="disabled" checked={formData.aereo} />
                                &nbsp;Aéreo
                                <span className='checkmark' >
                                    <GetICon type={"fas"} icon={"check"} size={"xs"} />
                                </span>
                            </label>
                            <label className="check-custom" >
                                <input type="checkbox" name="noApplicable"  checked={noApplicable} onChange={()=>{changeTrasport('noApplicable',!noApplicable)}}/> 
                                &nbsp;No aplica
                                <span className='checkmark' >
                                    <GetICon type={"fas"} icon={"check"} size={"xs"} />
                                </span>
                            </label>
                            <div className="error-form error-form-left" >{ validateError.transport.state ? validateError.transport.msg : "" } </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <label>Observaciones</label>
                            <textarea name="observaciones" className="form-control" defaultValue={formData.observaciones} onChange={changeInput} ></textarea>
                        </div>
                        {listResolutionsSelectedDir.length>0 &&    
                            <div> 
                                <div className="col-12" >
                                    <label>
                                        Direcciones de transporte
                                        <Tooltip title={"Agregar direcciones de recorrido"} arrow >
                                            <span>
                                            <GetICon type={"fas"} icon={"question-circle"} size={"sm"} onClick={ ()=>{  }  } /> &nbsp;
                                            </span>
                                        </Tooltip>
                                    </label>
                                    <div className="btn btn-info btn-add" onClick={showModalDirections} >
                                        <GetICon type={"fas"} icon={"compass"} size={"lg"} /> &nbsp;
                                        Agregar
                                    </div><br/>
                                </div>
                                <div className='parent-dir' >
                                    {listResolutionsSelectedDir.map(item=>{
                                        if(item.cheked){
                                            return(
                                                <div className='item-dir' key={"view_dir_"+item.ID}  >{item.DES}</div>
                                            )
                                        }

                                    })}
                                </div>
                            </div>
                        }
                        <div className="col-12 col-md-3" ></div>
                        <div className="col-12 col-md-5" >
                            {formData.maritimo &&
                                <div>
                                    <label>Transporte marítimo</label><br/>
                                    Matricula Transporte: <span className='transport-preview' >{maritimeValue}</span>
                                </div>

                            }
                            {formData.fluvial &&
                                <div>
                                    <label>Transporte Fluvial</label><br/>
                                    Matricula Transporte: <span className='transport-preview' >{riverValue}</span>
                                </div>

                            }
                            {formData.terrestre &&
                                <div>
                                    <label>Transporte Terrestre</label><br/>
                                    Documento: <span className='transport-preview' >{landDocValue}</span><br/>
                                    Nombre: <span className='transport-preview' >{landNameValue}</span><br/>
                                    Placa: <span className='transport-preview' >{landPlateValue}</span><br/>
                                    Tipo: <span className='transport-preview' >{landTypeValue}</span><br/>
                                </div>

                            }
                            {formData.aereo &&
                                <div>
                                    <label>Transporte Aereo</label><br/>
                                    Nombre Aereolinea: <span className='transport-preview' >{aerialValue}</span>
                                </div>

                            }
                        </div>
                        <div className="col-12 col-md-4" ></div>
                    </div><br/><br/> <hr/>
                    <div className="row" >
                        <div className="col-12 col-md-4" >
                            <div className="parent-input-search" >
                                <label className="label-full" >
                                    Departamento Origen *
                                    <Tooltip title={"Departamento de Origen"} arrow >
                                    <span>
                                        <GetICon type={"fas"} icon={"question-circle"} size={"sm"} /> &nbsp;
                                    </span>
                                    </Tooltip>
                                </label><br/>
                                <DropDowncomponent 
                                    selected={selectedDeparmentOri} 
                                    change={changeDeparmentOri}  
                                    list={listDeparmentsShowInputOrg}
                                    select={selectDeparmentOri}
                                    state={validateError.depori.state}
                                    msg={validateError.depori.msg}
                                    focus={focusInDeparmentOri}
                                    format = {["DEP_NOM"]} 
                                    key="depori"
                                    valueKey={"DEP_COD"}
                                    />
                            </div>
                        </div>
                        <div className="col-12 col-md-4" >
                            <div className="parent-input-search" >
                                <label className="label-full" >
                                    Municipio Origen *
                                    <Tooltip title={"Municipio de Origen"} arrow >
                                    <span>
                                        <GetICon type={"fas"} icon={"question-circle"} size={"sm"} onClick={ ()=>{ console.log(" presionado o algo asi") }  } /> &nbsp;
                                    </span>
                                    </Tooltip>
                                </label><br/>
                                <DropDowncomponent 
                                    selected={selectedMunicipalityOri} 
                                    change={changeMunicipalityOri}  
                                    list={showLMuniOriCurrent}
                                    select={selectMunicipalityOrg}
                                    state={validateError.munori.state}
                                    msg={validateError.munori.msg}
                                    focus={focusInMunicipalityOrg}
                                    format = {["MUN_NOM"]} 
                                    key="munori"
                                    valueKey={"MUN_COD"}
                                    />
                            </div>
                        </div>
                        <div className="col-12 col-md-4" >
                            <label className="label-full" >
                                Sitio de embarque *
                                <Tooltip title={"Sitio donde se hace el embarque"} arrow >
                                <span>
                                    <GetICon type={"fas"} icon={"question-circle"} size={"sm"}  /> &nbsp;
                                </span>
                                </Tooltip>
                            </label><br/>
                            <input type="text" name="embarque" className="form-control" defaultValue={formData.embarque} onChange={changeInput}/>
                            <div className="error-form" >{ validateError.embarque.state ? validateError.embarque.msg : "" } </div>
                        </div>
                        <div className="col-12 col-md-4" >
                            <div className="parent-input-search" >
                                <label className="label-full" >
                                    Departamento Destino *
                                    <Tooltip title={"Departamento de Destino"} arrow >
                                    <span>
                                        <GetICon type={"fas"} icon={"question-circle"} size={"sm"} /> &nbsp;
                                    </span>    
                                    </Tooltip>
                                </label><br/>
                                <DropDowncomponent 
                                    selected={selectedDeparmentDes} 
                                    change={changeDeparmentDes}  
                                    list={listDeparmentsShowInputDes}
                                    select={selectDeparmentDes}
                                    state={validateError.depdes.state}
                                    msg={validateError.depdes.msg}
                                    focus={focusInDeparmentDes}
                                    format = {["DEP_NOM"]} 
                                    key="depdes"
                                    valueKey={"DEP_COD"}
                                    />
                            </div>
                        </div>
                        <div className="col-12 col-md-4" >
                            <div className="parent-input-search" >
                                <label className="label-full" >
                                    Municipio Destino *
                                    <Tooltip title={"Municipio de Destino"} arrow >
                                    <span>
                                        <GetICon type={"fas"} icon={"question-circle"} size={"sm"}  /> &nbsp;
                                    </span>
                                    </Tooltip>
                                </label><br/>
                                <DropDowncomponent 
                                    selected={selectedMunicipalityDes} 
                                    change={changeMunicipalityDes}  
                                    list={showLMuniDesCurrent}
                                    select={selectMunicipalityDes}
                                    state={validateError.mundes.state}
                                    msg={validateError.mundes.msg}
                                    focus={focusInMunicipalityDes}
                                    format = {["MUN_NOM"]} 
                                    key="mundes"
                                    valueKey={"MUN_COD"}
                                    />
                            </div>
                        </div>
                        <div className="col-12 col-md-4" >
                            <label>
                                Cuenca 
                            </label>
                            <input type="text" placeholder="" className="form-control" defaultValue={formData.cuenca} disabled="disabled"  />
                        </div>
                    </div><br /><br/> <hr/>
                    <h4 style={{fontSize:"22px",textAlign:'center'}} >Especies transportadas *</h4>
                    {errorVolumen &&
                        <div style={{color:"red",textAlign:"center",fontSize:"18px",marginBottom:"8px"}}  >
                            La cantidad a movilizar es mayor a la disponible
                        </div>
                    }
                    <div className="row" >
                        <div className="btn btn-info btn-add" data-toggle="modal" data-target="#exampleModal" onClick={showModal} title="Agregar al menos una especie" >
                            <GetICon type={"fas"} icon={"plus-circle"} size={"lg"} /> &nbsp;
                            Agregar
                        </div>
                        <div className="error-form" >{ validateError.list.state ? validateError.list.msg : "" } </div>
                    </div><br/><br/> <hr/>
                    <div className="row" >
                        <div className="cnt-table">
                            <table className="table table-striped" >
                                <thead className="thead-light" >
                                    <tr>
                                        <th className='safe-col-1' >Cód. especie</th>
                                        <th className='safe-col-2' >Nombres Comunes</th>
                                        <th className='safe-col-3' >Nombre Científico</th>
                                        <th className='safe-col-4' >Volumen {unidSelected}</th>
                                        <th className='safe-col-5' >Forma de presentación</th>
                                        <th className='safe-col-7' ></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listSelecteSpecies.map(item=>{

                                        return (
                                            <tr key={item.pos} >
                                                <td>{item.code}</td>
                                                <td>{item.name}</td>
                                                <td>{item.sci}</td>
                                                <td>{item.vol}</td>
                                                <td>{item.pre_nom}</td>
                                                <td>{item.type}</td>
                                                <td>
                                                    <div className="btn btn-error btn-remove"  onClick={()=>{ removeSpecie(item.pos) }}  >
                                                        <GetICon type={"fas"} icon={"times-circle"} size={"lg"} /> &nbsp;
                                                    </div>
                                                </td>

                                            </tr>
                                        )
                                    })}

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div>
                        {!completeForm ?
                            <div className={sendingForm ? "btn btn-success btn-save-disabled" : "btn btn-success btn-save" } data-toggle="modal" data-target="#exampleModal" style={{float:"right"}} title="Salvar Salvoconducto"
                            onClick={sendForm} >
                                <GetICon type={"fas"} icon={"save"} size={"lg"} /> &nbsp;
                                Salvar
                            </div>
                            :
                            <span></span>
                        }
                        {completeForm ?
                            <><div className="btn btn-save btn-exit" data-toggle="modal" data-target="#exampleModal" style={{ float: "right" }} title="Salvar Salvoconducto"
                                onClick={callGoBack}>
                                <GetICon type={"fas"} icon={"door-open"} size={"lg"} /> &nbsp;
                                Salir
                            </div><div className="btn btn-download" data-toggle="modal" data-target="#exampleModal" style={{ float: "right" }} title="Salvar Salvoconducto"
                                onClick={printSafe}>
                                    <GetICon type={"fas"} icon={"download"} size={"lg"} /> &nbsp;
                                    Descargar
                                </div></>
                            :
                            <span></span>
                        }
                    </div><br/> <br/>
                </div>
            {/*--------------------------------------------------------------------------*/ }
            <div className={modalSpecies ? "modal modal-view":"modal" } >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Especie transportada</h5>
                        </div>
                        <div className="modal-body">
                            <div className="row " >
                                <div className="col-12" >
                                    <h5>Producto a transportar</h5>
                                </div>
                            </div>
                            <div className="row content-module" >
                                <div className="col-12" >
                                <label>
                                        Código *
                                        <Tooltip title={"Debe elegir un código de la lista"} arrow >
                                        <span>
                                            <GetICon type={"fas"} icon={"question-circle"} size={"sm"} /> &nbsp;
                                        </span>
                                        </Tooltip>
                                    </label>
                                    <div className="parent-input-search" >
                                        <DropDowncomponent 
                                            selected={selectecCodeSpecie} 
                                            change={changeTextSpeciesCode}  
                                            list={listSpeciesResolutions}
                                            select={selectSpecie}
                                            state={validateEspecies.nomcomun.state}
                                            msg={validateEspecies.nomcomun.msg}
                                            focus={()=>{}}
                                            format = {["MUN_NOM"]} 
                                            key="especie"
                                            valueKey={"ID"}
                                            type="species"
                                            />
                                    </div>
                                </div>
                                <div className="col-12" >
                                    <label>
                                    Nombres Comunes *
                                    <Tooltip title={"Debe elegir un nombre de la lista"} arrow >
                                        <span>
                                            <GetICon type={"fas"} icon={"question-circle"} size={"sm"} /> &nbsp;
                                        </span>
                                    </Tooltip>
                                    </label>
                                    <div className="parent-input-search" >
                                        <DropDowncomponent 
                                            selected={selectedNameSpecie} 
                                            change={changeTextSpecies}  
                                            list={listSpeciesResolutions}
                                            select={selectSpecie}
                                            state={validateEspecies.nomcomun.state}
                                            msg={validateEspecies.nomcomun.msg}
                                            focus={()=>{}}
                                            format = {["MUN_NOM"]} 
                                            key="especie"
                                            valueKey={"ID"}
                                            type="species"
                                            />
                                    </div>
                                </div>
                                <div className="col-12 col-md-8" >
                                    <label>Nombre Científico </label>
                                    <input type="text" name="terminal" placeholder="" className="form-control" value={selectedScientySpecie} disabled="disabled" readOnly/>
                                </div>
                                <div className="col-12 col-md-4" >
                                    <label>
                                        Volumen {unidSelected}*
                                        <Tooltip title={"Debe agregar el volumen de carga"} arrow >
                                        <span>
                                            <GetICon type={"fas"} icon={"question-circle"} size={"sm"} /> &nbsp;
                                        </span>
                                        </Tooltip>
                                    </label>
                                    <input type="number" min="0" step="1" className="form-control" value={selectedMovSpecie} onChange={(e)=>changeVolSpecie(e.target.value)} />
                                    <div className="error-form error-form-left" >{ validateEspecies.vol.state ? validateEspecies.vol.msg : "" } </div>
                                </div>
                                <div className="col-12" >
                                    <label>
                                        Forma de presentacion *
                                        <Tooltip title={"Debe selecionar la forma de preserntación"} arrow >
                                        <span>
                                            <GetICon type={"fas"} icon={"question-circle"} size={"sm"} /> &nbsp;
                                        </span>
                                        </Tooltip>
                                    </label>
                                    <div className="parent-input-search" >
                                        <DropDowncomponent 
                                            selected={selectedTypeSpecie} 
                                            change={changeTextSpeciesFormat}  
                                            list={listPresentationSpecies}
                                            select={selectSpecieFormat}
                                            state={validateEspecies.pre.state}
                                            msg={validateEspecies.pre.msg}
                                            focus={focusInSpeciesFromat}
                                            format = {["NAME"]} 
                                            key="present"
                                            valueKey={"ID"}
                                            />
                                    </div> 
                                </div>
                            </div><br/>
                        </div>
                        <div className="modal-footer" >
                            <div className="row" >
                                <div className="col-12 col-md-3 btn-bottom" >
                                    <button type="button" className="btn btn-primary" onClick={saveSpecie} >Aceptar</button>
                                </div>
                                <div className="col-12 col-md-3 btn-bottom" >
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={showModal} >Cancelar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*--------------------------------------------------------------------------*/ }
            <div className={modalTransport ? "modal modal-view":"modal" } >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Datos de los vehiculos de transporte</h5>
                        </div>
                        <div className="modal-body">
                            <div className="row content-module" >
                                <div className='col-12 ' >
                                    <label>
                                        <input checked={maritimeCheck} disabled={noApplicable?true:false}  type="checkbox" onChange={()=>{changeTrasport('maritime',!maritimeCheck)}} />
                                        <span>Transporte marítimo </span>
                                    </label>
                                </div>
                                <div className={maritimeCheck ? "col-12  transport-true":"transport-check" } >
                                    <label>
                                        Matrícula transporte *
                                        <Tooltip title={"Debe agregar la Matricula de transporte"} arrow >
                                        <span>
                                            <GetICon type={"fas"} icon={"question-circle"} size={"sm"} /> &nbsp;
                                        </span>
                                        </Tooltip>
                                    </label>

                                    <input type="text" name="mat_maritimo"  className="form-control" defaultValue={maritimeValue} onChange={updateTranportValues} />
                                </div>
                                <div className="col-12" >
                                    <label> 
                                        <input checked={riverCheck} type="checkbox" disabled={noApplicable?true:false}  onChange={()=>{changeTrasport('river',!riverCheck)}} />
                                        <span>Transporte fluvial</span> 
                                    </label>
                                </div>
                                <div className={riverCheck ? "col-12  transport-true":"transport-check" } >
                                    <div className="parent-input-search" >
                                        <label>
                                            Matrícula transporte *
                                            <Tooltip title={"Debe agregar la Matricula de transporte"} arrow >
                                            <span>
                                                <GetICon type={"fas"} icon={"question-circle"} size={"sm"} /> &nbsp;
                                            </span>
                                            </Tooltip>
                                        </label>
                                        <input type="text" name="mat_fluvial" className="form-control" defaultValue={riverValue} onChange={updateTranportValues} />
                                    </div>
                                </div>
                                <div className="col-12" >
                                    <label>
                                        <input type="checkbox" checked={aerialCheck} disabled={noApplicable?true:false}  onChange={()=>{changeTrasport('aerial',!aerialCheck)}} />
                                        <span>Transporte aéreo</span> 
                                    </label>
                                </div>
                                <div className={aerialCheck ? "col-12  transport-true":"transport-check" } >
                                    <label>
                                        Nombre Aerolinea *
                                        <Tooltip title={"Debe agregar el nombre de la aerolinea"} arrow >
                                        <span>
                                            <GetICon type={"fas"} icon={"question-circle"} size={"sm"} /> &nbsp;
                                        </span>
                                        </Tooltip>
                                    </label>
                                    <input type="text" name="nom_aereo" className="form-control" defaultValue={aerialValue} onChange={updateTranportValues} />
                                </div>
                                <div className="col-12" >
                                    <label>
                                        <input type="checkbox" checked={landCheck} disabled={noApplicable?true:false}  onChange={()=>{changeTrasport('land',!landCheck)}} />
                                        <span>Transporte Terrestre</span> 
                                    </label>
                                </div>
                                <div className={landCheck ? "col-12 col-md-4  transport-true":"transport-check" } >
                                    <label>
                                        Documento
                                        <Tooltip title={"Debe agregar el documento del conductor"} arrow >
                                        <span>
                                                <GetICon type={"fas"} icon={"question-circle"} size={"sm"} /> &nbsp;
                                        </span>
                                        </Tooltip>
                                    </label>
                                    <input type="text" name="doc_terrestre" className="form-control" defaultValue={landDocValue} onChange={updateTranportValues} />
                                </div>
                                <div className={landCheck ? "col-12 col-md-8  transport-true":"transport-check" }  >
                                    <label>
                                        Nombres
                                        <Tooltip title={"Debe el nombre del conductor"} arrow >
                                        <span>
                                                <GetICon type={"fas"} icon={"question-circle"} size={"sm"} /> &nbsp;
                                        </span>
                                        </Tooltip>
                                    </label>
                                    <input type="text" name="nom_terrestre" className="form-control" defaultValue={landNameValue} onChange={updateTranportValues} />
                                </div>
                                <div className={landCheck ? "col-12 col-md-4  transport-true":"transport-check" } >
                                    <label>
                                        Placa
                                        <Tooltip title={"Debe agregar la placa del vehiculo"} arrow >
                                        <span>
                                                <GetICon type={"fas"} icon={"question-circle"} size={"sm"} /> &nbsp;
                                        </span>
                                        </Tooltip>
                                    </label>
                                    <input type="text" name="placa_terrestre" className="form-control" defaultValue={landPlateValue} onChange={updateTranportValues} />
                                </div>
                                <div className={landCheck ? "col-12 col-md-8  transport-true":"transport-check" } >
                                    <label>
                                        Tipo
                                        <Tooltip title={"Debe agregar el tipo de vehiculo"} arrow >
                                        <span>
                                                <GetICon type={"fas"} icon={"question-circle"} size={"sm"} /> &nbsp;
                                        </span>
                                        </Tooltip>
                                    </label>
                                    <input type="text" name="tipo_terrestre" className="form-control" defaultValue={landTypeValue} onChange={updateTranportValues} />
                                </div>
                                <div className="col-12" >
                                    <label>
                                        <input type="checkbox" checked={noApplicable} onChange={()=>{changeTrasport('noApplicable',!noApplicable)}} />
                                        <span>No Aplica</span> 
                                    </label>
                                </div>
                            </div><br/>
                        </div>
                        <div className="modal-footer" >
                            <div className="row" >
                                <div className="col-12 col-md-3 btn-bottom" >
                                    <button type="button" className="btn btn-primary" onClick={saveTransport} >Aceptar</button>
                                </div>
                                <div className="col-12 col-md-3 btn-bottom" >
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={showModalTranport} >Cancelar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*--------------------------------------------------------------------------*/ }
            <div className={modalResolutions ? "modal modal-view":"modal" } >
                <div className="modal-dialog modal-resolutions" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Seleccione Resolución</h5>
                        </div>
                        <div className="modal-body">
                            <div className="row content-module" >
                                {
                                    resolutionsButtons.map(item=>{
                                        
                                            return (
                                                <div key={item.ID} className="btn btn-info btn-resolution" onClick={()=>{selecteResolutionPrev(item)}}  >{item.PRM_NOM} </div>
                                            );
                                        }
                                    )

                                }
                            </div>
                         </div>
                        <div className="modal-footer" >
                            <div className="row" >
                                <div className="col-12 col-md-3 btn-bottom" >
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={callGoBack} >Cancelar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*--------------------------------------------------------------------------*/ }
            <div className={modalResolutionsUnits ? "modal modal-view":"modal" } >
                <div className="modal-dialog modal-resolutions" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Seleccione Unidad </h5>
                        </div>
                        <div className="modal-body">
                            <div className="row content-module" >
                                {   
                                    medida.map(item=>{
                                            
                                            let insert = false;

                                            if(item.pos===1 && Number(permissionsSelected.APL_CON_SLV) === 1 ){  insert =true }
                                            if(item.pos===2 && Number(permissionsSelected.APL_UNC_SLV) === 1 ){  insert =true }
                                            if(item.pos===3 && Number(permissionsSelected.APL_UNV_SLV) === 1 ){  insert =true }

                                            if(insert){
                                                return (
                                                    <div key={item.pos} className="btn btn-info btn-resolution" onClick={()=>{selectResolutionsUnit(item.pos)}}  >{item.name} </div>
                                                );
                                            }
                                        }
                                    )
                                }
                            </div>
                        </div>
                        <div className="modal-footer" >
                            <div className="row" >
                                <div className="col-12 col-md-3 btn-bottom" >
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={callGoBack} >Cancelar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*--------------------------------------------------------------------------* / }
            <div className={modalResolutionsUnitsCom ? "modal modal-view":"modal" } >
                <div className="modal-dialog modal-resolutions" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Seleccione Unidad </h5>
                        </div>
                        <div className="modal-body">
                            <div className="row content-module" >
                                {   
                                    medidaCultivo.map(item=>{
                                        
                                        return (
                                                <div key={item.pos} className="btn btn-info btn-resolution" onClick={()=>{selectResolutionsUnit(item.pos)}}  >{item.name} </div>
                                            );
                                        }
                                    )
                                }
                            </div>
                        </div>
                        <div className="modal-footer" >
                            <div className="row" >
                                <div className="col-12 col-md-3 btn-bottom" >
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={callGoBack} >Cancelar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*--------------------------------------------------------------------------*/ }
            {directionRsoShow &&
                <DirectionsModal 
                    view={directionRsoShow} 
                    save={aceptDirections} 
                    cancel={showModalDirections} 
                    list={listResolutionsSelectedDir} 
                    listRemissions={listResolutionsRemissions}
                    />
            }
            </div>
        </div>
    );
}