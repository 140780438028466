import { useEffect, useState } from "react"
import {getVedas } from '../../../services/storage'
import { useHistory } from "react-router-dom";
import { GetICon } from '../../../utils/commons'

const sortListColumns = {AMB_NOM:true,ESP_NOM_JSO:true,ESP_NOM_CIE:true,FCH_DES:true,FCH_HAS:true}

export function VedasComponents()
{
    const [listVedas,setListVedas] = useState([]);
    const [listViewVedas,setViewListVedas] = useState([]);
    const [sortCol,setSortCol] = useState(sortListColumns);

    let history = useHistory()
    
    const callGoBack =  ()=>{
        history.goBack();
    }

    const sortBy = (type)=>
    {
        let current = listVedas;
        let proColumns = sortCol;

        current.sort((a,b)=>{

            if(proColumns[type])
            {
                if(a[type].toLowerCase() < b[type].toLowerCase()) return -1;
                if(a[type].toLowerCase() > b[type].toLowerCase()) return 1;
                return 0;
                
            }else{

                //proColumns[type] =  true;
                if(b[type].toLowerCase() < a[type].toLowerCase()) return -1;
                if(b[type].toLowerCase() > a[type].toLowerCase()) return 1;
                return 0;
            }

        }).map((item)=>{

            return item;
        })

        proColumns[type] = !proColumns[type] ;

        setSortCol(proColumns);
        setViewListVedas([...[],...current]);//*/
    }//*/

    useEffect( ()=>{

        (async ()=>{
            
            setListVedas(JSON.parse(getVedas()));
            setViewListVedas(JSON.parse(getVedas()));
        })();

    },[])

    
    

    return(
        <div className="parent-component" >
            <button className="btn btn-primary return return-margin" onClick={callGoBack} > 
                <GetICon type={"fas"} icon={"arrow-alt-circle-left"} size={"lg"} /> &nbsp;
                Regresar
            </button><br/><br/>
            <h1 className="h1-vedas" >Especies vedadas</h1>
            {/*<h2>Áreas de veda </h2>}
            <div style={{marginTop:"18px"}} >
                {/*<label>
                    <h4 style={{fontSize:"22px"}} >Departamento</h4>
                    {/*<input type="text" name="user" placeholder="Departamento" class="form-control" onChange={changeInput}  />
                    {ViewDeparmet.length > 0 ?
                        <div>

                        </div>
                        :
                        <div></div>
                    }}
                </label><br/>}
                <select onChange={changeDeparment} >
                    {departments.map(depto=>{
                       return(<option key={"depto_"+depto.DEP_COD} value={depto.DEP_COD} >{depto.DEP_NOM}</option>)
                    })}
                </select><br/><br/>
                <label>
                    <h4 style={{fontSize:"22px"}} >Municipios</h4>
                    {/*<input type="text" name="user" placeholder="Departamento" class="form-control" onChange={changeInput}  />
                    {ViewDeparmet.length > 0 ?
                        <div>

                        </div>
                        :
                        <div></div>
                    }* /}
                </label><br/>
                <select onChange={changeMuni} >
                    {ViewMuni.map(muni=>{
                       return(<option key={"muni_"+muni.MUN_COD} value={muni.MUN_COD} >{muni.MUN_NOM}</option>)
                    })}
                </select><br/><br/>
            </div>//*/}
            <div className="cnt-table" >
                <table className="table table-striped" >
                    <thead className="thead-light" >
                        <tr>
                            <th className="veda-col-1" onClick={()=>{sortBy("TIP_NOM")}}  >Tipo</th>
                            <th className="veda-col-1" onClick={()=>{sortBy("ESP_COD_INT")}}  >Código interno</th>
                            <th className="veda-col-3" onClick={()=>{sortBy("ESP_NOM_CIE")}} >Nombre Cientifico</th>
                            <th className="veda-col-2" onClick={()=>{sortBy("ESP_NOM_JSO")}} >Nombres Comunes</th>
                            <th className="veda-col-1" onClick={()=>{sortBy("AMB_NOM")}}  >Ámbito</th>
                            <th className="veda-col-4" onClick={()=>{sortBy("FCH_DES")}} >Desde</th>
                            <th className="veda-col-5" onClick={()=>{sortBy("FCH_HAS")}} > Hasta</th>
                            <th className="veda-col-3" onClick={()=>{sortBy("ESP_NOM_CIE")}} >Causa de Veda</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            listViewVedas.map((veda)=>{
                                
                                return (
                                    <tr key={"vada_"+veda.ID} >
                                        <td>{veda.TIP_NOM}</td>
                                        <td>{veda.ESP_COD_INT}</td>
                                        <td>{veda.ESP_NOM_CIE}</td>
                                        <td>{veda.ESP_NOM_JSO}</td>
                                        <td>{veda.AMB_NOM}</td>
                                        <td>{veda.FCH_DES}</td>
                                        <td>{veda.FCH_HAS}</td>
                                        <td>
                                            {veda.AMB==="0" && "Ámbito "+veda.AMB_NOM}
                                            {veda.AMB==="1" && "Departamento: "+veda.DEP_NOM}
                                            {veda.AMB==="2" && "Municipio: "+veda.MUN_NOM}
                                            {veda.AMB==="3" && "Cuenca: "+veda.CUE_NOM}
                                        </td>
                                    </tr>)
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}