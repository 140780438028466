
export  function GenerateDocument(pdf,nameDate)
{
    const link = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");

    const fileName = "salvoconducto_"+nameDate+".pdf";

    downloadLink.href = link;
    downloadLink.download = fileName;
    downloadLink.click();
}

