const userReducer = (state=false, action )=>{

    switch(action.type)
    {
        case 'UPDATE_SESSION':
            return action.payload;
        default:
            return false; 

    }
}

export default userReducer;