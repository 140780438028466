import React,{ useEffect,useState  } from "react"
import { useHistory } from "react-router-dom";
import { GetICon } from '../../../utils/commons';
import { getUserResolutions } from "../../../services/queries";
import { getUser } from '../../../services/storage'

function HistoryResolutionComponent(){

    const [listBase,setListBase] = useState([]);
    const [listCurrent,setListCurrent] = useState([]);

    let history = useHistory()

    const callGoBack =  ()=>{
        history.goBack();
    }

    const changeSelect = event =>{

        let filterList = listBase
        const value = event.target.value;

        if(event.target.value<2)
        {   
            filterList = listBase.filter((item)=> Number(item.ACT)===Number(value));
        }

        setListCurrent([...[],...filterList]);
    }

    useEffect(()=>{

        

        (async ()=>{

            const user = JSON.parse(getUser());
            let listBase = await getUserResolutions(user.USU_ID);

            let baseList = [...listCurrent,...listBase.res];

            setListBase(baseList);
            setListCurrent(baseList);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return(
        <div className="parent-component" >
            <button className="btn btn-primary return return-margin" onClick={callGoBack} > 
                <GetICon type={"fas"} icon={"arrow-alt-circle-left"} size={"lg"} /> &nbsp;
                Regresar
            </button><br/><br/>
            <h2>Historial Resoluciones</h2>
            <div className="cnt-history" >
                <div className="cnt-select" >
                    <select className="form-control" onChange={changeSelect} >
                        <option value="2" >Todos</option>
                        <option value="1" >Activos</option>
                        <option value="0" >Vencidos</option>
                    </select>
                </div>
                <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                    <div className="cnt-table" >
                        <table className="table table-striped" >
                        <thead className="thead-light" >
                                <tr>
                                    <th>No Resolución</th>
                                    <th>Tipo permiso</th>
                                    <th>Estado</th>
                                    <th>Fecha Vencimiento</th>
                                    <th>Kilogramos</th>
                                    <th>Alevinos</th>
                                    <th>Unidades</th>
                                </tr>
                            </thead>
                            <tbody>
                            {listCurrent.map(item=>{

                                

                                return (
                                    <tr key={item.NUM} >
                                        <td>{item.NUM}</td>
                                        <td>{item.PRM_NOM}</td>
                                        <td>
                                            {
                                                item.ACT ===1  ? <span>Activo</span> : <span>Inactivo</span> 
                                            }
                                        </td>
                                        <td>{item.FCH_VEN}</td>
                                        <td>
                                            <div>
                                                <label>Autorizado:&nbsp;</label>
                                                        <span>{item.KGS_AUT}</span>
                                            </div>
                                            <div>
                                                <label>Disponible:&nbsp;</label>
                                                        <span>{item.KGS_DIS}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <label>Autorizado:&nbsp;</label>
                                                        <span>{item.ALV_AUT}</span>
                                            </div>
                                            <div>
                                                <label>Disponible:&nbsp;</label>
                                                        <span>{item.ALV_DIS}</span>
                                            </div>
                                        </td>
                                        <td><div>
                                                <label>Autorizado:&nbsp;</label>
                                                        <span>{item.UND_AUT}</span>
                                            </div>
                                            <div>
                                                <label>Disponible:&nbsp;</label>
                                                        <span>{item.UND_DIS}</span>
                                            </div>
                                        </td>
                                    </tr>
                                )
                                })}
                            </tbody>
                        </table>
                    </div>
                    {/*<div className="safe-content" >
                                <div className="row" >
                                    <div className="col-5" >N. salvoconducto</div>
                                    <div className="col-7" >
                                    <input type="text" name="user" placeholder="" className="form-control" />
                                    </div>
                                </div><br/>
                                <div className="row" >
                                <div className="col-5" >Fecha de expedicion del salvoconducto</div>
                                <div className="col-7" >
                                <input type="date" name="user" placeholder="" className="form-control" />
                                </div>
                                </div><br/>
                                <div className="row" >
                                <div className="col-5" >Fecha de vencimiento del salvoconducto</div>
                                <div className="col-7" >
                                <input type="date" name="user" placeholder="" className="form-control" />
                                </div>
                                </div><br/>
                                <div className="row" >
                                    <div className="col-5" >Volumen Autorizado</div>
                                    <div className="col-7" >
                                    <input type="text" name="user" placeholder="" className="form-control" />
                                    </div>
                                </div><br/>
                                <div className="row" >
                                    <div className="col-5" >N. Salvoconducto (proroga)</div>
                                    <div className="col-7" >
                                    <input type="text" name="user" placeholder="" className="form-control" />
                                    </div>
                                </div><br/>
                            </div>/*/}
                    </div>
                    <div className="tab-pane fade show " id="nav-pendientes" role="tabpanel" aria-labelledby="nav-pendientes-tab">
                    <h4>Resoluciones Pendientes</h4>
                    <table className="table table-striped" >
                        <tbody>
                            
                        </tbody>
                    </table>
                    {/*<div className="safe-content" >
                                <div className="row" >
                                    <div className="col-5" >N. salvoconducto</div>
                                    <div className="col-7" >
                                    <input type="text" name="user" placeholder="" className="form-control" />
                                    </div>
                                </div><br/>
                                <div className="row" >
                                <div className="col-5" >Fecha de expedicion del salvoconducto</div>
                                <div className="col-7" >
                                <input type="date" name="user" placeholder="" className="form-control" />
                                </div>
                                </div><br/>
                                <div className="row" >
                                <div className="col-5" >Fecha de vencimiento del salvoconducto</div>
                                <div className="col-7" >
                                <input type="date" name="user" placeholder="" className="form-control" />
                                </div>
                                </div><br/>
                                <div className="row" >
                                    <div className="col-5" >Volumen Autorizado</div>
                                    <div className="col-7" >
                                    <input type="text" name="user" placeholder="" className="form-control" />
                                    </div>
                                </div><br/>
                                <div className="row" >
                                    <div className="col-5" >N. Salvoconducto (proroga)</div>
                                    <div className="col-7" >
                                    <input type="text" name="user" placeholder="" className="form-control" />
                                    </div>
                                </div><br/>
                            </div>//*/}
                    </div>
                    <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                    <h4>Resoluciones vencidas</h4>
                    <table className="table table-striped" >
                        <tbody>
                        </tbody>
                    </table>
                    {/*<div className="safe-content" >
                                <div className="row" >
                                    <div className="col-5" >N. salvoconducto</div>
                                    <div className="col-7" >
                                    <input type="text" name="user" placeholder="" className="form-control" />
                                    </div>
                                </div><br/>
                                <div className="row" >
                                <div className="col-5" >Fecha de expedicion del salvoconducto</div>
                                <div className="col-7" >
                                <input type="date" name="user" placeholder="" className="form-control" />
                                </div>
                                </div><br/>
                                <div className="row" >
                                <div className="col-5" >Fecha de vencimiento del salvoconducto</div>
                                <div className="col-7" >
                                <input type="date" name="user" placeholder="" className="form-control" />
                                </div>
                                </div><br/>
                                <div className="row" >
                                    <div className="col-5" >Volumen Autorizado</div>
                                    <div className="col-7" >
                                    <input type="text" name="user" placeholder="" className="form-control" />
                                    </div>
                                </div><br/>
                                <div className="row" >
                                    <div className="col-5" >N. Salvoconducto (proroga)</div>
                                    <div className="col-7" >
                                    <input type="text" name="user" placeholder="" className="form-control" />
                                    </div>
                                </div><br/>
                            </div>//*/}

                    </div>
                </div>
            </div>
        </div>
    )

}

export default HistoryResolutionComponent;
//export default withRouter(MainMenuComponent);