import React,{ useEffect,useState } from "react"
import { useHistory } from "react-router-dom";
import { getAllSafePassage,removeSafePassage,getDocument } from "../../../services/queries";
import { GetICon,commonToast,msgIcons,commonConfirm,commonMsg, generateKey } from '../../../utils/commons';
import { GenerateDocument, } from '../../../utils/pdf';
import { getUser } from '../../../services/storage';
import { SignalWifiStatusbarNullOutlined } from "@mui/icons-material";


function HistorySafePassageComponent(){

    let history = useHistory()
    const [listBase,setListBase] = useState([]);
    const [listCurrent,setListCurrent] = useState([]);
    const [,setCurrentDateText] = useState();
    const [currentDate,setCurrentDate] = useState();


    const callGoBack =  ()=>{
        history.goBack();
    }
    
    const changeSelect = event =>{

        let filterList = listBase
        const value = event.target.value;

        if(value<2)
        {   
            filterList = listBase.filter((item)=>{ 
                
                let itemDate =new Date(item.FCH_TRA);
                let itemDateEnd =new Date(item.FCH_VEN);

                if(Number(value)===item.ACT && Number(value)===1 && currentDate.getTime()>= itemDate.getTime() && currentDate.getTime()<= itemDateEnd.getTime()){
                    return item; 
                }else if(Number(value)===item.ACT && Number(value)===0){
                    return item;
                }else{
                    return SignalWifiStatusbarNullOutlined;
                }
            });
        }

        setListCurrent([...filterList]);
    }

    const download = (id) =>{

        getDocument(id)
        .then(data=>{

            const date = new Date();
            const nameDate = date.getDate()+""+(date.getMonth()+1)+""+date.getFullYear();

            if(data.state){ GenerateDocument(data.pdf,nameDate); }
            else{
                commonMsg("Error al intentar generar documento",data.mng, msgIcons.error)
            }//*/

        })
        .catch(error=>{

        })//*/

    }

    const removeSafe = (item) => {
        
        const data = {
            "item":item,
            title:"Desea remover este salvoconducto?",
            text:item.NUM
        }
        
        commonConfirm(data,confirmRemove);
        
    }

    const confirmRemove = (item) =>{

        const user =  JSON.parse(getUser());
        
        const data = {
            "ID_SLV":item.ID,
            "ACC_TOK":user.ACC_TOK,
        }

        removeSafePassage(data)
        .then(data=>{

            if(data.state)
            {
                commonToast("Cancelación Autorizada",msgIcons.success);
                history.go(0);
            }else{
                commonMsg("Información",data.mng, msgIcons.warning)
            }
        })
        .catch(error=>{

        })//*/

    }

    useEffect(()=>{

       

        (async ()=>{

            const currentDate = new Date();

            const formatDayRaw = currentDate.getFullYear()+"-"+(currentDate.getMonth()<9? "0":"")+(currentDate.getMonth()+1)
            +"-"+(Number(currentDate.getDate())<10 ? "0":"")+(currentDate.getDate());
           //const formatDay =  formatDayRaw+"T00:00:00";
            //format day to compare
            const currentDateFormat = new Date(formatDayRaw);
    
            setCurrentDateText(formatDayRaw);
            setCurrentDate(currentDateFormat);

            const user = JSON.parse(getUser());

            let listBase = await getAllSafePassage(user.USU_ID);

            let listBaseFilter =  listBase.slv.map(item=>{

                let currentData = {};
                currentData = item;

                let espcies = listBase.slv_esp.filter(esp=>esp.SLV===item.ID);
                currentData["especie"] = espcies;

                return currentData ;

            })

            let baseList = [...listCurrent,...listBaseFilter];

            setListBase(baseList);
            setListCurrent(baseList);

        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return(
        <div className="parent-component" >
            <button className="btn btn-primary return return-margin" onClick={callGoBack} > 
                <GetICon type={"fas"} icon={"arrow-alt-circle-left"} size={"lg"} /> &nbsp;
                Regresar
            </button><br/><br/>
            <h2 className="h2-history" >Historial Salvocondutos</h2>
            <div className="cnt-history" >
                <div className="cnt-select" >
                    <select className="form-control" onChange={changeSelect} >
                        <option value="2" >Todos</option>
                        <option value="1" >Activos</option>
                        <option value="0" >Inactivos</option>
                    </select>
                </div>
                <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                    <div className="cnt-table" >
                        <table className="table table-striped" >
                            <thead className="thead-light" >
                                <tr>
                                    <th >Id</th>
                                    <th >No. Resolución</th>
                                    <th >Descargar</th>
                                    <th >Cancelar</th>
                                    <th >Estado</th>
                                    <th >Fecha Transporte</th>
                                    <th >Fecha Vencimiento </th>
                                    <th >Volumen Movilizado</th>
                                    <th >Titular</th>
                                    <th >Tipo Permiso</th>
                                    <th >Medida</th>
                                    <th >Transporte</th>
                                    <th >Origen</th>
                                    <th >Destino</th>
                                    <th >Especies</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listCurrent.map(item=>{

                                    let itemDate =new Date(item.FCH_TRA);
                                    let itemDateEnd =new Date(item.FCH_VEN);

                                    return (
                                        <tr key={generateKey(item.ID+"")}  >
                                            <td className="col-sal-1" >{item.NUM}</td>
                                            <td className="col-sal-2" >{item.RSO_OTO}</td>
                                            <td className="col-sal-2" >
                                                <button className="btn btn-primary btn-table" onClick={()=>{download(item.ID)}} > 
                                                    <GetICon type={"fas"} icon={"download"} size={"lg"} /> &nbsp;
                                                </button><br/><br/>
                                            </td>
                                            <td className="col-sal-2" >
                                                { item.ACT === 1 ? 
                                                    <>
                                                    
                                                        <button className="btn btn-primary btn-table-remove" onClick={()=>{removeSafe(item)}} > 
                                                            <GetICon type={"fas"} icon={"window-close"} size={"lg"} /> &nbsp;
                                                        </button><br/><br/>
                                                    </>
                                                :
                                                    <>
                                                        <button className="btn btn-primary btn-table-remove" disabled > 
                                                            <GetICon type={"fas"} icon={"window-close"} size={"lg"} /> &nbsp;
                                                        </button><br/><br/>
                                                    </>
                                                }
                                            </td>
                                            <td className="col-sal-3" >
                                                { item.ACT === 1 && currentDate.getTime()>= itemDate.getTime() &&  
                                                    currentDate.getTime()<= itemDateEnd.getTime()
                                                ? <span>Activo</span> 
                                                :
                                                <span>inactivo</span>
                                                }
                                            </td>
                                            <td className="col-sal-3" >{item.FCH_TRA}</td>
                                            <td className="col-sal-3" >{item.FCH_VEN}</td>
                                            <td className="col-sal-4" >{item.VOL}</td>
                                            <td className="col-sal-5" >{item.ven}
                                                <div>
                                                    <label>Doc. Titular:&nbsp;</label>
                                                    <span>{item.ENT_CIF}</span>
                                                </div>
                                                <div>
                                                    <label>Nombre:&nbsp;</label>
                                                    <span>{item.ENT_NOM}</span>
                                                </div>
                                                <div>
                                                    <label>Correo:&nbsp;</label>
                                                    <span>{item.ENT_EML}</span>
                                                </div>
                                                <div>
                                                    <label>Telefono:&nbsp;</label>
                                                    <span>{item.ENT_TLF}</span>
                                                </div>
                                            </td>
                                            <td className="col-sal-6" >{item.PRM_NOM}</td>
                                            <td className="col-sal-8" >{item.MED_NOM}</td>
                                            <td className="col-sal-7" >
                                                {item.TRA_MAR === 1 ?
                                                    <div>
                                                        <label>Matricula Trasporte marítimo:&nbsp;</label>
                                                        <span>{item.MAT_MAR}</span>
                                                    </div>
                                                : <span></span>
                                                }
                                                {item.TRA_FLU === 1 ?
                                                    <div>
                                                        <label>Matricula Trasporte fluvial:&nbsp;</label>
                                                        <span>{item.MAT_FLU}</span>
                                                    </div>
                                                : <span></span>
                                                }
                                                {item.TRA_AER === 1 ?
                                                    <div>
                                                        <label>nombre de Aerolinea:&nbsp;</label>
                                                        <span>{item.AER}</span>
                                                    </div>
                                                : <span></span>
                                                }
                                                {item.TRA_TER === 1 ?
                                                    <div>
                                                        <div>
                                                            <label>Documento Conductor:&nbsp;</label>
                                                            <span>{item.CON_DOC}</span>
                                                        </div>
                                                        <div>
                                                            <label>Nombre Conductor:&nbsp;</label>
                                                            <span>{item.CON_NOM}</span>
                                                        </div>
                                                        <div>
                                                            <label>Placa Vehiculo:&nbsp;</label>
                                                            <span>{item.VEH_PLA}</span>
                                                        </div>
                                                        <div>
                                                            <label>Tipo Vehiculo:&nbsp;</label>
                                                            <span>{item.VEH_TIP}</span>
                                                        </div>
                                                    </div>
                                                : <span></span>
                                                }

                                            </td>
                                            <td className="col-sal-9" >
                                                <div>
                                                    <label>Departamento:&nbsp;</label>
                                                    <span>{item.DEP_ORI_NOM}</span>
                                                </div>
                                                <div>
                                                    <label>Municipio:&nbsp;</label>
                                                    <span>{item.MUN_ORI_NOM}</span>
                                                </div>
                                                <div>
                                                    <label>Cuenca:&nbsp;</label>
                                                    <span>{item.CUE_NOM}</span>
                                                </div>
                                                <div>
                                                    <label>Embarque:&nbsp;</label>
                                                    <span>{item.SIT_EMB}</span>
                                                </div>
                                            </td>
                                            <td className="col-sal-10" >
                                                <div>
                                                    <label>Departamento:&nbsp;</label>
                                                    <span>{item.DEP_DES_NOM}</span>
                                                </div>
                                                <div>
                                                    <label>Municipio:&nbsp;</label>
                                                    <span>{item.MUN_DES_NOM}</span>
                                                    
                                                </div>
                                            </td>
                                            <td className="col-sal-11 col-scroll" >
                                                <div className="col-scroll" >
                                                    {
                                                        item.especie.map(especie=>{
                                                            return(<div className="cnt-module" >
                                                                <div>
                                                                    <label>Nombre Común:&nbsp;</label>
                                                                    <span>{especie.ESP_NOM_COM}</span>
                                                                </div>
                                                                <div>
                                                                    <label>Presentacion:&nbsp;</label>
                                                                    <span>{especie.PRE_NOM}</span>
                                                                </div>
                                                                <div>
                                                                    <label>Volumen:&nbsp;</label>
                                                                    <span>{especie.VOL}</span>
                                                                </div>
                                                            </div>)
                                                        })
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default HistorySafePassageComponent;
//export default withRouter(MainMenuComponent);