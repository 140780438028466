const SET_LAST_UPDATE = "set_last_date";
const SET_DEPARMENTS = "set_deparments";
const SET_MUNICIPALITY = "set_municipality";
const SET_FORMAT_PRESENTATION = "set_format_presentation";
const SET_VEDAS = "set_vedas";
const SET_ORNA = "set_orna";
const SET_PERMISSIONS = "set_permissions";
const SET_REMISSIONS = "set_remissions";
const SET_INFO = "set_info";
const SET_VALIDATE_DAYS = "set_validate_days";
const SET_USER = "set_user";
const SET_USER_TYPE = "set_user_type";
const SET_USER_SESSION = "set_user_session";
const SET_SAFE_SESSION = "set_safe_session";


export function setDateUpdate(data)
{
    if(data)
        localStorage.setItem(SET_LAST_UPDATE,data)
    else
        localStorage.removeItem(SET_LAST_UPDATE)
}

export function setDeptos (data)
{
    if(data)
        localStorage.setItem(SET_DEPARMENTS,data);
    else
        localStorage.removeItem(SET_DEPARMENTS); 
}   

export function setMunicipality (data)
{
    if(data)
        localStorage.setItem(SET_MUNICIPALITY,data);
    else
        localStorage.removeItem(SET_MUNICIPALITY); 
}

export function setFormatPresentation(data)
{
    if(data)
        localStorage.setItem(SET_FORMAT_PRESENTATION,data);
    else
        localStorage.removeItem(SET_FORMAT_PRESENTATION);
}

export function setVedas(data)
{
    if(data)
        localStorage.setItem(SET_VEDAS,data);
    else
        localStorage.removeItem(SET_VEDAS);
}

export function setLimitOrnamentales(data){
    if(data)
        localStorage.setItem(SET_ORNA,data);
    else
        localStorage.removeItem(SET_ORNA);
}

export function setPermissions(data)
{
    if(data)
        localStorage.setItem(SET_PERMISSIONS,data);
    else
        localStorage.removeItem(SET_PERMISSIONS);
}

export function setRemissions(data)
{
    if(data)
        localStorage.setItem(SET_REMISSIONS,data);
    else
        localStorage.removeItem(SET_REMISSIONS);
}

export function setInfo(data)
{
    if(data)
        localStorage.setItem(SET_INFO,data);
    else
        localStorage.removeItem(SET_INFO);
}

export function setValidDays(data){
    if(data)
        localStorage.setItem(SET_VALIDATE_DAYS,data);
    else
        localStorage.removeItem(SET_VALIDATE_DAYS);
}

export function setUser(data)
{
    if(data)
        localStorage.setItem(SET_USER,data);
    else
        localStorage.removeItem(SET_USER); 
}

export function setUserType(data)
{
    if(data)
        localStorage.setItem(SET_USER_TYPE,data);
    else
        localStorage.removeItem(SET_USER_TYPE); 
}

export function setSessionUser(data){

    if(data)
        localStorage.setItem(SET_USER_SESSION,data);
    else
        localStorage.removeItem(SET_USER_SESSION); 
}

export function setSessionSafe(data){

    if(data)
        localStorage.setItem(SET_SAFE_SESSION,data);
    else
        localStorage.removeItem(SET_SAFE_SESSION);
}

export function getDateUpdate(data)
{
    return localStorage.getItem(SET_LAST_UPDATE)
}

export function getDeptos(){
    return localStorage.getItem(SET_DEPARMENTS);
}

export function getMunicipality(){
    return localStorage.getItem(SET_MUNICIPALITY);
}

export function getFormatPresentation(data)
{
    return localStorage.getItem(SET_FORMAT_PRESENTATION);
}

export function getVedas(data){
    return    localStorage.getItem(SET_VEDAS);
}

export function getLimitOrnamentales(data){
    return    localStorage.getItem(SET_ORNA);
}


export function getPermissions(data){
    return    localStorage.getItem(SET_PERMISSIONS);
}

export function getRemissions(data){
    return    localStorage.getItem(SET_REMISSIONS);
}

export function getInfo(data){
    return    localStorage.getItem(SET_INFO);
}

export function getValidDays(){
     return localStorage.getItem(SET_VALIDATE_DAYS);
}

export function getUser(){
    return localStorage.getItem(SET_USER)
}

export function getUserType(){
    return localStorage.getItem(SET_USER_TYPE)
}

export function getSessionUser(){
    return localStorage.getItem(SET_USER_SESSION);
}

export function getSessionSafe(){
    return localStorage.getItem(SET_SAFE_SESSION);
}
