//import modules
import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";


//const variables
export const msgIcons = {success:"success", error:"error", warning:"warning", info:"info", question:"question"}

export function commonsTitle(title){

    const baseTitle = document.title ;

    document.title = baseTitle + " ("+title+")";
}

export function validateEmail(email)
{
    //eslint-disable-next-line
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
    {
        return true;
    }
    
    return false;
}

export function commonMsg(title="",text="",icon="info",goback=null,goBackCall=null){ //success, error, warning, info, question
    Swal.fire({
        icon:icon,
        title:title,
        text:text
    }).then((result)=>{

        if(goback){
            goBackCall()
        }
    })
}   

export function commonToast(text="",icon="info",confirm=false,timer=2800){
    //success, error, warning, info, question

    const Toast = Swal.mixin({
        toast:true,
        timer:timer,
        showConfirmButton:confirm,
    });

    Toast.fire({
        icon:icon,
        title:text
    })

}

export function commonSimpleAnswer(callback){

    Swal.fire({
        title:"Recuperacion de contraseña",
        input:"email",
        inputAttributes: {
            placeholder:"Ingrese su usuario",
            autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Enviar',
        cancelButtonText: "Cancelar",
        inputValidator:(value)=>{

            const validEmail = validateEmail(value);

            if(validEmail){

            }else{
                commonMsg("Errro en el envio ","El correo electronico sumistrado no es valido ","error" )
                return;
            }

        }
    }).then((result)=>{


    });

}

export function comonHtmlForm(data,callback,goback=false,callgoback=null){

    
    let inputs = ""

    for(let index in data.list)
    {
        const item = data.list[index]
        inputs +="<input id='"+item.id+"' type='"+item.type+"' placeholder='"+item.placeholder+"' class='swal2-input' autocomplete='off'  >";
    }

    inputs+="<button type='button' title='Enviar datos del formulario' class='swal2-confirm swal2-styled'  >Enviar</button>";
    inputs+="<button type='button' title='Cerrar la ventana' class='swal2-cancel swal2-styled'  >Cancelar</button>";

    Swal.fire({
        title:data.title,
        html:inputs,
        focusConfirm:false,
        showCancelButton: false,
        showConfirmButton: false,
        confirmButtonText: 'Enviar',
        cancelButtonText: "Cancelar",
        backdrop:'rgba(0,0,0,0.2)',
        width:"540",
        padding:"60",
        

    })/*.then((result)=>{
        
        if(result.isConfirmed)
        {
            callback(result.value);
        }else if(goback){
            callgoback()
        }
    })//*/;//*/

    const content = Swal.getHtmlContainer();

    content.getElementsByClassName('swal2-confirm')[0].addEventListener('click',function()
    {
        let inputResult  = {};
            
            for(let key in data.list)
            {
                let item = data.list[key];
                let valueItem = document.getElementById(item.id).value;
                    
                if(item.required)
                {
                    if(item.type==="text" && valueItem === "")
                    {
                        commonToast(item.errorMsg,"warning")
                        inputResult = false;
                        if(goback){ callgoback() }
                        break;//*/
                    }//*/
                }//* /
                    
                
                inputResult[item.id] = valueItem;//*/
            }//*/

            if(inputResult!==false)
            {
                callback(inputResult);
                Swal.close();
            }else if(goback){
                callgoback()
            }

            //return inputResult//*/

    });

    content.getElementsByClassName('swal2-cancel')[0].addEventListener('click',function(){
        Swal.close();
    });



} 

export function commonConfirm(data,callback,goback=false,callgoback=null){

    Swal.fire({
        title:data.title,
        html:data.text,
        icon:msgIcons.warning,
        showConfirmButton:true,
        confirmButtonText:"Confirmar",
        showCancelButton:true,
        cancelButtonText:"Cancelar"
    }).then((result)=>{
        
        if(result.isConfirmed){ callback(data.item); }
    });
}

export function GetICon(props)
{
    return(
        <FontAwesomeIcon icon={[props.type,props.icon]} size={props.size} />
    )
}

export function validateDate(registerDate,orientation="equal"){
    let current = new Date();
    let last = new Date(registerDate);
    let diff = current.getTime() - last.getTime();
    //let seconds = Math.floor(diff/1000);
    //let minutes = Math.floor(diff/1000/60);
    //let hours = Math.floor(diff/1000/60/60);
    let days  = Math.floor(diff/1000/60/60/24);
    
    if(orientation==="major")
    {
        if(days >1 ){  }
        


    }else{

    }
}

export const generateKey = (name)=>{

    return `${name}_${new Date().getTime()}`;

}

